import React, { useState, useEffect } from 'react';

import closeSvg from 'assets/icons/close-white.svg';
import warningSvg from 'assets/icons/warning.svg';

import {
  Button,
  Icon,
  Container,
  TextWrapper,
  InsideIcon,
  DetectionText,
  LinkButton,
  AlertBall,
} from './DetectionLink.style';

function DetectionLink({
  text,
  detection,
  showNotifications,
  factor,
}: {
  text: string,
  detection: boolean,
  showNotifications?: () => void,
  factor: any,
}) {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (detection) {
      setOpen(true);
    }
  }, [detection]);

  useEffect(() => {
    if (factor.filter((n: string | null) => n !== null).length === 0) {
      setAmount(0);
      return;
    }
    if (factor) setAmount(amount + 1);
  }, [factor]);

  const showOpen = open;

  return (
    <Container $open={showOpen}>
      <TextWrapper>
        <InsideIcon src={warningSvg} />
        <DetectionText>
          {detection ? (
            <>
              {text}
              !
              {' '}
              <LinkButton onClick={showNotifications}>
                Ver detecção
              </LinkButton>
            </>
          ) : (
            <LinkButton onClick={showNotifications}>
              Histórico de detecções
            </LinkButton>
          )}
        </DetectionText>
      </TextWrapper>
      <Button $clickable $open={showOpen} onClick={() => setOpen(!open)}>
        <Icon src={showOpen ? closeSvg : warningSvg} />
        {(amount > 0 && !showOpen) && (
          <AlertBall>{amount <= 5 ? amount : '5+'}</AlertBall>
        )}
      </Button>
    </Container>
  );
}

DetectionLink.defaultProps = {
  showNotifications: () => { },
};

export default DetectionLink;
