import React from 'react';

import { StyledSlider } from './Slider.style';

type SliderProps = {
  onChange: (value: number) => void,
  value: number,
  marks: { [key: number]: string },
  min: number,
  max: number,
};

function Slider({
  onChange,
  value,
  marks,
  min,
  max,
}: SliderProps) {
  return (
    <StyledSlider
      onChange={onChange}
      value={value}
      min={min}
      max={max}
      marks={marks}
      tipFormatter={null}
      defaultValue={1}
    />
  );
}

export default Slider;
