import React from 'react';

import {
  BackgroundBase,
} from './SecondaryBackground.style';

function SecondaryBackground({
  children,
  mobileHigher = false,
}: {
  children: React.ReactNode,
  mobileHigher?: boolean,
}) {
  return (
    <BackgroundBase mobileHigher={mobileHigher}>
      {children}
    </BackgroundBase>
  );
}

SecondaryBackground.defaultProps = {
  mobileHigher: false,
};

export default SecondaryBackground;
