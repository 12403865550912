import styled from 'styled-components';

import { flexcc } from 'styles/variables';

export const Button = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  margin-bottom: 29px;
  width: 35px;
  height: 35px;
  backdrop-filter: blur(9px) brightness(0.7);
  background-color: rgb(80, 74, 74, 0.25);
  border: none;
  border-radius: 50px;
  outline: none;
  cursor: pointer;
  ${flexcc}

  @media only screen and (min-width: 600px) {
    top: 0;
    bottom: unset;
  }
`;

export const Icon = styled.img`
  width: 10px;
`;
