import styled from 'styled-components';

export const Button = styled.button<{ $marginRight: number, $marginBottom: number }>`
  width: 35px;
  height: 35px;
  border-radius: 50px;
  box-shadow: 0 5px 36px 0 rgba(12, 46, 59, 0.24);
  background-color: white;
  padding: 0;
  outline: none;
  border: none;
  position: fixed;
  bottom: 0;
  right: 0;
  color: black;
  cursor: pointer;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: ${({ $marginBottom, $marginRight }) => `${$marginBottom}px ${$marginRight}px`};

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const Icon = styled.img`
  width: 17px;
`;
