import styled from 'styled-components';

import colors from 'styles/colors';
import { flexcc, flex } from 'styles/variables';

export const Base = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  width: 100%;
  height: 5rem;

  padding: 0 1.57rem;

  @media only screen and (max-width: 750px) {
    padding: 0.5rem 0;
  }
`;

export const NavItem = styled.div`
  ${flexcc}
`;

export const LogoContainer = styled.div`
  ${flex('flex-start', 'center', 'unset', 'unset')}
  position: relative;
  img {
    height: 3rem;
    object-fit: cover;
  }
`;

export const BetaContainer = styled.div`
  ${flex('space-between', 'center', 'unset', 'unset')}

  width: 9.64rem;
  height: 2.21rem;

  padding: 0 0.35rem;

  border-radius: 5px;
  background-color: rgba(85, 121, 183, 0.12);
`;

export const LiveDemoText = styled.p`
  margin: 0 auto;

  font-size: 0.93rem;
  font-weight: 800;
  line-height: 1.35;
  text-align: center;
  color: ${colors.blue};
`;

export const BetaText = styled.p`
  ${flex('center', 'center', 'unset', 'unset')}

  padding: 1px 5px 3px;
  border-radius: 3px;
  border: solid 1px #5579b7;

  margin: 0 auto;

  font-size: 0.79rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #5579b7;
`;

export const Separator = styled.div`
  width: 0;
  height: 52px;
  border: solid 1px #dbdbdb;

  margin: 0 1.11rem;
`;

export const FeedbackContainer = styled.a`
  ${flex('flex-end', 'center', 'unset', 'unset')}
  flex-direction: column;

  margin-right: 8px;

  span {
    font-size: 0.79rem;
    font-weight: bold;
    font-family: 'San Francisco', Helvetica, Arial, san-serif;
    line-height: 1.64;
    text-align: center;
    color: #4e4e4e;
  }
`;
