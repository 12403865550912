import { useState, useEffect } from 'react';

function useVideoHeight(breakpoint: number = 900) {
  const [isLower, setIsLower] = useState<boolean | null>(window.innerHeight <= breakpoint);

  useEffect(() => {
    const checkMobile = () => setIsLower(window.innerHeight <= breakpoint);

    window.addEventListener('resize', checkMobile);
    window.addEventListener('load', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
      window.removeEventListener('load', checkMobile);
    };
  }, []);

  return isLower ? 500 : 720;
}

export default useVideoHeight;
