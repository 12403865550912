import styled from 'styled-components';

import { flex, flexcc } from 'styles/variables';

export const Container = styled.div`
  ${flexcc}
  height: 100%;
  width: 100%;
`;

export const Image = styled.img`
  width: 50%;
  height: 100%;
  object-fit: contain;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const Footer = styled.footer`
  ${flex('center', 'center', 'row', 'nowrap')}
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;

  width: 100%;
  min-height: 5rem;
  padding: 0 100px;

  &::after {
    position: absolute;
    width: 100%;
    height: 1px;
    top: 0;
    content: '';
    z-index: 3;
    box-shadow: 0 -3px 6px 0 rgb(0 0 0 / 16%);
  }
`;
