import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: rgba(213, 7, 10, 0.541);
  backdrop-filter: blur(9px) brightness(0.7);
  border: 1px solid #dd3e3e;
  border-radius: 50px;
  color: white;
  padding: 6px 50px;
  margin: 10px;
  margin-bottom: 42px;

  @media only screen and (min-width: 600px) {
    bottom: unset;
    top: 0;
  }
`;
