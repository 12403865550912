import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { flex, flexcc } from 'styles/variables';

const backgroundColor = '#f1f1f1';

export const Wrapper = styled.div`
  width: 486px;
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 90px;
  background-color: #f5f5f5;
  overflow-x: hidden;
  border-radius: 5px;
  margin: ${({ $marginBottom }: { $marginBottom: number }) =>
      $marginBottom ? `${$marginBottom}px` : '50px'}
    auto;

  @media only screen and (max-width: 700px) {
    width: 400px;
  }
`;

export const Container = styled.ul`
  ${flex('flex-end', 'flex-end', 'row', 'nowrap')}
  user-select: none;
  margin: 0 auto;
  width: 70%;
  height: 100%;
  padding: 0;
`;

export const LinkContainer = styled.div<{ margin: number }>`
  ${flex('flex-end', 'center', 'row', 'nowrap')}
  margin: 0 ${({ margin }) => (margin ? `${margin}px` : '0px')};
  margin-bottom: 15px;
  width: max-content;
`;

export const CustomLink = styled(Link)<{ margin: number; width: number }>`
  ${flexcc}
  position: relative;
  z-index: 1;
  height: ${({ width }) => (width ? `${width}px` : '0px')};
  width: ${({ width }) => (width ? `${width}px` : '0px')};
  margin: 0 ${({ margin }) => (margin ? `${margin / 2}px` : '0px')};
  overflow: hidden;

  &:first-child {
    margin-left: 0;
  }
  &:nth-last-child(1) {
    margin-right: 0;
  }
`;

export const Card = styled.li<{ active: boolean }>`
  ${flex('center', 'center', 'column', 'nowrap')}
  list-style: none;
  border-radius: 50px;
  padding: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: box-shadow 0.5s ease-in-out, background-color 0.5s ease-in-out,
    filter 0.5s ease-in-out;
  background-color: white;

  img {
    filter: invert(63%) sepia(9%) saturate(446%) hue-rotate(188deg)
      brightness(87%) contrast(83%);
    transition: filter 0.5s ease-in-out;
  }

  ${({ active }) => {
    if (active) {
      return css`
        img {
          filter: unset;
        }
      `;
    }
    return null;
  }}

  p {
    transition: color 0.5s ease-in-out;
    font-size: 0.6rem;
    text-transform: capitalize !important;
    text-align: center;
  }
`;

export const SelectionRingWrapper = styled.div`
  width: 49px;
  height: 49px;
  background-image: linear-gradient(
    132deg,
    #7dceff -24%,
    #3f83fe 13%,
    #82d3ff 102%
  );
  background-color: transparent;
  border-radius: 50px;
  ${flexcc}
  position: absolute;
  bottom: 0;
  margin-bottom: -17px;
  left: 50%;
  transform: translate(-50%, -50%);

  @media only screen and (max-width: 700px) {
    width: 40px;
    height: 40px;
  }
`;

export const SelectionRing = styled.div`
  width: 90%;
  height: 90%;
  background-color: ${backgroundColor};
  border-radius: 50px;
`;

export const Blank = styled.div<{ maxWidth: number }>`
  height: 1px;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '0px')};
  transition: max-width 0.3s ease-in-out;
`;

export const Dash = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  margin: 0 0 7.5px;
  background-image: linear-gradient(
    to right,
    #7dceff 0%,
    #3f83fe 29%,
    #82d3ff 98%
  );
`;

export const NameContainer = styled.button`
  ${flexcc}
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;
`;

export const Name = styled.p`
  text-align: center;
  color: #0c0d34;
  font-size: 12px;
  margin: 0;
`;

export const MoveButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  box-shadow: 0 5px 36px 0 rgba(12, 46, 59, 0.24);
  position: absolute;
  margin: 0 13px;
  bottom: 0;
  margin-bottom: 17.5px;
  /* transform: translateY(-50%); */
  border: none;
  cursor: pointer;
  outline: none;
`;

export const ForwardButton = styled(MoveButton)`
  right: 0;
`;

export const BackwardsButton = styled(MoveButton)`
  left: 0;
`;
