import React from 'react';

import {
  Card,
  Title,
  Dash,
  Content,
} from './PageCard.style';

function PageCard({ children, title }: { children: React.ReactNode, title: string }) {
  return (
    <Card>
      <Title>
        {title}
        <Dash />
      </Title>
      <Content>
        {children}
      </Content>
    </Card>
  );
}

export default PageCard;
