import React from 'react';

import switchCamSvg from 'assets/icons/switch-cam.svg';

import { Button, Icon } from './SwitchCam.style';

function SwitchCam({ onClick }: { onClick: () => void }) {
  return (
    <Button onClick={onClick}>
      <Icon src={switchCamSvg} />
    </Button>
  );
}

export default SwitchCam;
