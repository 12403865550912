import styled from 'styled-components';
import { Slider } from 'antd';

export const StyledSlider = styled(Slider)`
  .ant-slider-track {
    background-color: #4a70fc !important;
    z-index: 2;
  }
  .ant-slider-mark-text {
    color: #4e4e4e !important;
  }
  .ant-slider-dot {
    width: 0;
    height: 18px;
    border: solid 1px #efefef;
    transform: translate(150%, -25%);
  }
  .ant-slider-handle {
    width: 25px;
    height: 25px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    transform: translate(-50%, -25%) !important;
    border: none;
    z-index: 3;
  }
  .ant-slider-mark-text {
    font-size: 12px;
    white-space: nowrap;
  }
  .ant-slider-step {
    background-color: #efefef;
  }
  margin: 20px 6px 38px;
`;
