import React from 'react';

import { SecondaryBackground } from 'components/atoms';
import { AccessCard } from 'components/molecules';

function Access() {
  return (
    <SecondaryBackground mobileHigher>
      <AccessCard mobile />
    </SecondaryBackground>
  );
}

export default Access;
