import styled from 'styled-components';

import { flexcc, flex } from 'styles/variables';
import colors from 'styles/colors';

export const AccessBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #011027;
  width: 98%;
  border-radius: 15px;
  padding: 10px 8%;

  @media only screen and (max-width: 600px) {
    padding: 10px;
    height: calc(100vh - 176px);
    flex-grow: 1;
  }
  @media only screen and (min-width: 600px) {
    height: 80%;
    width: 60%;
  }

  @media only screen and (min-width: 1000px) {
    height: 95%;
    width: 80%;
  }

  @media only screen and (min-width: 1660px) {
    height: 80%;
    width: 70%;
  }
`;

export const TitleContainer = styled.div`
  ${flex('flex-start', 'center', 'column', 'nowrap')}
  padding-top: 0.7rem;
  h1 {
    text-align: center;
  }

  @media only screen and (min-width: 1660px) {
    padding-top: 1.43rem;
  }
`;

export const TextContainer = styled.div`
  ${flex('flex-start', 'center', 'column', 'nowrap')}
  h1 {
    text-align: center;
  }

  @media only screen and (max-width: 800px) {
    h1 {
      font-size: 1rem;
    }
    button {
      padding: 5px 10px;
      font-size: 0.8rem;
    }
  }

  @media only screen and (min-width: 600px) {
    h1 {
      font-size: 1.2rem;
    }
    button {
      font-size: 1rem;
    }
  }
`;

export const FooterContainer = styled.div`
  ${flex('center', 'center', 'column', 'nowrap')}
`;

export const SubTitle = styled.h2`
  color: #7aaffd;
  text-align: center;
  font-size: 0.857rem;
  margin: 12px 0 0;

  @media only screen and (min-width: 600px) {
    font-size: 1.075rem;
  }
`;

export const AccessIcon = styled.img`
  width: 15px;
  margin: 0 0 0 25px;
`;

export const AccessItemContainer = styled.ul`
  ${flex('flex-start', 'center', 'column', 'nowrap')}
  padding: 0;
  width: 100%;
  margin-top: 2rem;

  @media only screen and (max-width: 800px) {
    margin: 0;
  }
`;

export const AccessItem = styled.li`
  list-style: none;
  display: flex;
  align-items: flex-start;
  margin: 0.9rem 0;
  width: 90%;
  max-width: 35rem;

  @media only screen and (max-width: 800px) {
    line-height: 90%;
  }

  @media only screen and (mix-width: 1600px) {
    margin: 1.43rem 0;
  }
`;

export const AccessNumber = styled.div`
  margin: 0 1rem 0 0;
  border-radius: 50%;
  border: 1px solid ${colors.orange};
  color: ${colors.orange};
  ${flexcc}
  font-weight: bold;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1rem;

  @media only screen and (max-width: 800px) {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
  }

  @media only screen and (min-width: 600px) {
    font-size: 1.2rem;
  }
`;

export const AccessText = styled.p`
  color: white;
  margin: 0;
  width: 100%;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;

  @media only screen and (min-width: 600px) {
    font-size: 0.95rem;
  }
`;

export const Image = styled.img`
  width: 20px;
  max-height: 20px;
  margin: 5px 10px 0;

  color: white;

  @media only screen and (max-width: 1000px) {
    margin-top: 0;
  }
`;

export const Button = styled.button`
  background-color: white;
  color: black;
  border-radius: 50px;
  padding: 8px 32px;
  font-size: 0.7rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  border: none;
  outline: none;

  display: flex;
  align-items: center;

  margin: 1.8rem 0;
  cursor: pointer;

  @media only screen and (min-width: 600px) {
    font-size: 0.8rem;
  }

  @media only screen and (min-width: 1660px) {
    margin: 3.8rem 0 1.8rem 0;
  }
`;

export const BottomText = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  color: white;
  width: 90%;
  font-size: 0.5625rem;
  opacity: 0.6;
  text-align: center;

  max-width: 37rem;

  display: block;

  a {
    color: #a5a5a5;
    font-weight: bold;
    text-decoration: underline;
  }

  @media only screen and (max-width: 800px) {
    min-width: 22rem;
    margin-bottom: 0.9rem;
    font-size: 0.6rem;
  }

  @media only screen and (min-width: 600px) {
    width: unset;
    font-size: 0.7625rem;
  }
  @media only screen and (max-width: 1000px) {
    margin-top: 0.5rem;
  }

  @media only screen and (min-width: 1660px) {
    width: unset;
    font-size: 0.7625rem;
    margin: 2rem 0 1rem 0;
  }
`;
