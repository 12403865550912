import styled from 'styled-components';

export const VideoDisplay = styled.div<{ invert: boolean }>`
  width: 1280px;
  height: 720px;
  display: flex;
  justify-content: center;
  background-color: #c3c3c3;

  video {
    width: 100%;
    object-fit: contain;
    transform: ${({ invert }) => invert && 'scaleX(-1)'};
    position: relative;
  }

  @media only screen and (max-width: 600px) {
    width: 480px;
    height: 600px;
    background-color: transparent;
  }

  @media only screen and (min-width: 600px) {
    @media only screen and (max-height: 900px) {
      height: 500px;
    }
  }
`;

export const BrowserContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
`;
