import React, { useContext, useState } from 'react';

const OptionsContext = React.createContext([] as any);

function OptionsProvider({ children }: { children: React.ReactNode }) {
  const [open, setOpen] = useState(() => () => {});

  return (
    // @ts-ignore
    <OptionsContext.Provider value={[open, setOpen]}>
      {children}
    </OptionsContext.Provider>
  );
}

export const useOptionsDropdown = () => useContext(OptionsContext);

export default OptionsProvider;
