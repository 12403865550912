import React from 'react';

import undoSvg from 'assets/icons/undo.svg';

import { Button, Icon } from './UndoButton.style';

function UndoButton({ onClick }: { onClick: () => void }) {
  return (
    <Button onClick={onClick}>
      <Icon src={undoSvg} />
    </Button>
  );
}

export default UndoButton;
