import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { notification } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ChromePicker } from 'react-color';

import { useOptionsDropdown } from 'contexts/OptionsContext';
import { Slider } from 'components/atoms';
import trashIcon from 'assets/icons/red-trash.svg';

import {
  Card,
  Wrapper,
  Section,
  TitleContainer,
  Label,
  ButtonContainer,
  Button,
  ProgressBar,
  CheckboxContainer,
  CheckboxRow,
  Icon,
  Checkbox,
  CheckboxParagraph,
  SliderCurrentVal,
  CloseButton,
  ColorsContainer,
  ColorBall,
  ColorContainer,
  TrashIcon,
  TrashButton,
  ModalContainer,
  Shadow,
} from './Options.style';
import AnalyticInfo from './Mobile/AnalyticInfo';

const defaultConfig: OptionsConfig = {
  notify: ['mask', 'nomask', 'person'],
  quantity: 1,
  color: '#4BBFD1',
  time: 5,
  default: true,
  counter: 0,
};

function Options({
  notify,
  onChange,
  showQuantity = false,
  showTime = false,
  analyticInfo,
  showColorPicker = false,
}: OptionsProps) {
  const changeObjRef = useRef(defaultConfig);
  const [tempObj, setTempObj] = useState(defaultConfig);
  const [progress, setProgress] = useState(-2);
  const [firstChange, setFirstChange] = useState(true);
  const [active, setActive] = useState(false);
  const instantRef = useRef(false);
  const showMessageRef = useRef(true);
  const [showColor, setShowColor] = useState(false);
  const [colors, setColors] = useState(['#3f83fe', '#d5070a', '#3bb406', '#f58400']);

  const [, setOpen] = useOptionsDropdown();

  const handleChange = useCallback((obj: OptionsConfig, showMessageOverride: boolean = false) => {
    onChange({
      ...obj,
      counter: obj.default ? obj.counter : (obj.counter || 0) + 1,
    });
    if (!firstChange && (showMessageRef.current || showMessageOverride)) {
      notification.success({
        message: 'Mudanças feitas com sucesso!',
      });
    }
  }, [firstChange]);

  const generateHandler = (
    key: string,
    both: boolean = false,
    instant: boolean = false,
  ) => (value: any) => {
    setFirstChange(false);
    instantRef.current = instant;
    setTempObj((prevObj) => ({
      ...prevObj,
      default: false,
      [key]: value,
    }));
    if (both) {
      changeObjRef.current = {
        ...changeObjRef.current,
        default: false,
        [key]: value,
      };
    }
  };

  useEffect(() => {
    setOpen(() => () => {
      setActive(true);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      showMessageRef.current = false;
      handleChange({ ...defaultConfig, default: false });
    }, 200);
  }, []);

  useEffect(() => {
    if (progress === 5) {
      setTimeout(() => {
        setProgress(4);
      }, 0);
      return () => { };
    }
    if (progress > 0) {
      const timeout = setTimeout(() => {
        setProgress(progress - 1);
      }, 1000);

      return () => clearTimeout(timeout);
    }

    return () => { };
  }, [progress]);

  useEffect(() => {
    if (instantRef.current) {
      showMessageRef.current = false;
      handleChange({ ...tempObj });
      return () => { };
    }

    if (firstChange) return () => { };

    showMessageRef.current = true;

    setProgress(5);

    const timeoutId = setTimeout(() => {
      changeObjRef.current = { ...tempObj };
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [tempObj, firstChange]);

  useEffect(() => {
    if (progress === 0) {
      showMessageRef.current = true;
      setTimeout(() => {
        handleChange(changeObjRef.current, true);
      }, 1000);
    }
    if (progress === -1 && !firstChange) {
      handleChange(changeObjRef.current);
    }
    if (firstChange) {
      handleChange(changeObjRef.current);
    }
  }, [handleChange, progress, firstChange]);

  const handleFinish = () => {
    showMessageRef.current = true;
    setProgress(-1);
    changeObjRef.current = { ...tempObj };
  };

  return (
    <Card
      $active={active}
      $marginRight={window.innerWidth > 1280 ? (window.innerWidth - 1280) / 2 : 0}
    >
      <ProgressBar progress={progress * 20} />
      <TitleContainer>
        <AnalyticInfo analyticInfo={analyticInfo} />
        <CloseButton onClick={() => setActive(false)}><CloseOutlined /></CloseButton>
      </TitleContainer>
      <Wrapper>
        {notify && (
          <Section>
            <Label>Notificar</Label>
            <CheckboxContainer>
              {notify.map(({ icon, label, value }) => (
                <CheckboxRow key={label}>
                  <Icon src={icon} />
                  <CheckboxParagraph>{label}</CheckboxParagraph>
                  <Checkbox
                    defaultChecked={defaultConfig.notify.includes(value)}
                    checked={tempObj.notify.includes(value)}
                    onChange={(event) => {
                      let newValues = [...tempObj.notify];

                      if (event.target.checked) {
                        newValues.push(value);
                      } else {
                        newValues = newValues.filter((val) => val !== value);
                      }

                      generateHandler('notify')(newValues);
                    }}
                  />
                </CheckboxRow>
              ))}
            </CheckboxContainer>
          </Section>
        )}
        {showQuantity && (
          <Section>
            <Label>Número de pessoas</Label>
            <Slider
              value={tempObj.quantity || 0}
              min={0}
              max={5}
              onChange={(value: number) => { generateHandler('quantity')(value); }}
              marks={{
                0: '0',
                1: '1',
                2: '2',
                3: '3',
                4: '4',
                5: '5+',
              }}
            />
          </Section>
        )}
        {showTime && (
          <Section>
            <Label>Notificar a partir de</Label>
            <SliderCurrentVal>
              {tempObj.time || 0}
              {' '}
              seg
            </SliderCurrentVal>
            <Slider
              value={tempObj.time || 0}
              min={0}
              max={20}
              onChange={(value: number) => generateHandler('time')(value)}
              marks={{ 0: '0 seg', 20: '20 seg' }}
            />
          </Section>
        )}
        {showColorPicker && (
          <Section>
            <Label>Marcação da área de detecção</Label>
            <ColorsContainer>
              {colors.map((color) => (
                <ColorContainer active={color === tempObj.color} onClick={() => generateHandler('color', true, true)(`${color}`)}>
                  <ColorBall color={`${color}`} />
                </ColorContainer>
              ))}
              <TrashButton
                onClick={() => generateHandler('default', true, true)(true)}
              >
                <TrashIcon src={trashIcon} />
              </TrashButton>
            </ColorsContainer>
            {showColor && (
              <ModalContainer>
                <Shadow onClick={() => setShowColor(false)} />
                <ChromePicker
                  color={tempObj.color}
                  onChangeComplete={({ hex }) => {
                    generateHandler('color', true, true)(hex);
                    setColors((prevColors) => [...prevColors, hex]);
                  }}
                />
              </ModalContainer>
            )}
          </Section>
        )}
      </Wrapper>
      <ButtonContainer>
        <Button
          onClick={() => {
            setProgress(-1);
            instantRef.current = true;
            setTempObj({ ...defaultConfig });
            notification.success({
              message: 'Mudanças feitas com sucesso!',
            });
          }}
          clean
        >
          Aplicar Padrão
        </Button>
        <Button save onClick={handleFinish}>Executar</Button>
      </ButtonContainer>
    </Card>
  );
}

export type OptionsConfig = {
  notify: ('mask' | 'nomask' | 'person' | 'car' | 'truck' | 'bus')[],
  quantity?: number,
  time?: number,
  color?: string,
  default?: boolean | null,
  counter?: number,
}

export type OptionsProps = {
  notify?: { value: ('mask' | 'nomask' | 'person' | 'car' | 'truck' | 'bus'), label: string, icon?: string }[],
  showQuantity?: boolean,
  showColorPicker?: boolean,
  showTime?: boolean,
  onChange: (changeObj: typeof defaultConfig) => void,
  mobileHeight?: string,
  analyticInfo?: { name: string, description: string, icon: string },
};

export default Options;
