module.exports = ({
  white: '#FFFFFF',
  black: '#111111',
  primary: '#FF943B',
  bodyBackground: '#FFFFFF',
  secondaryBackground: '#B2B2B2',
  success: '#009A1D',
  warning: '#FF943B',
  error: '#D50808',
  info: '#0CAEAE',
  heading: '#FF943B',
  text: '#FFFFFF',
  textSecondary: '#FF943B',

  orange: '#FF943B',
  gray: '#CECECE',
  red: '#D50808',
  blue: '#5579B7',
  lightBlue: '#0297A6',
  lightGray: '#F5F5F5',
  secondaryGray: '#B2B2B2',
  deepBlue: '#4A70FC',
});
