import React, { useEffect, useState } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Modal as ShareModal } from 'antd';

import warningSvg from 'assets/icons/warning-black.svg';
import shareSvg from 'assets/icons/share-black.svg';
import avantiaLogo from 'assets/white-logo.png';
import { useAxios } from 'global/func';
import padTime from 'helpers/padTime';
import ShareFooterIcons from 'components/organisms/ShareFooter/ShareFooterIcons';

import {
  ModalBigImage,
  ModalImageContainer,
  ModalImage,
  ModalPlaceholder,
  Modal,
  Title,
  TitleIcon,
  Description,
  ImageWrapper,
  ImageFooter,
  Timestamp,
  ShareButton,
  ShareIcon,
  ModalImageWrapper,
  ForwardButton,
  BackwardsButton,
} from './LastNotificationsMobile.style';

function LastNotificationsModalMobile({
  visible,
  setVisible,
  urlList,
  times,
  amounts,
  text,
  infoType,
  detectionText,
  deleteEverything,
}: {
  visible: boolean,
  setVisible: (visible: boolean) => void,
  urlList: (string | null)[],
  times: (number | null)[],
  amounts: (number | null)[],
  text: string,
  infoType?: ('time' | 'amount' | null),
  detectionText: { singular: string, plural: string },
  deleteEverything: () => void,
}) {
  const [imageIndex, setImageIndex] = useState(0);
  const [mainImage, setMainImage] = useState('');
  const [placeholderImage, setPlaceholderImage] = useState('');
  const [fileId, setFileId] = useState('');
  const [shareVisible, setShareVisible] = useState(false);

  const [axiosPost] = useAxios('post');

  const date = new Date(times[imageIndex] || Date.now());

  useEffect(() => {
    const drawPlaceholder = async () => {
      const canvas = document.createElement('canvas');

      const ctx = canvas.getContext('2d');

      canvas.width = 480;
      canvas.height = 600;

      if (ctx) {
        ctx.fillStyle = '#011027';

        ctx.fillRect(0, 0, 480, 600);

        await new Promise((resolve) => {
          const image = new Image();
          image.style.opacity = '0.4';

          image.onload = () => {
            const imageWidth = image.width * 1;
            const imageHeight = image.height * 1;

            ctx.drawImage(image, canvas.width - imageWidth - 20, 20, imageWidth, imageHeight);
            resolve(image);
          };

          image.src = avantiaLogo;
        });

        ctx.textAlign = 'center';
        ctx.fillStyle = 'white';
        ctx.font = `bold ${ctx.font.replace(/\d+px/, '18px')}`;

        ctx.fillText('No momento, não existem detecções', canvas.width / 2, canvas.height / 2);
        ctx.fillText('a serem visualizadas', canvas.width / 2, (canvas.height / 2) + 20);
      }

      setPlaceholderImage(canvas.toDataURL('image/png'));
    };
    drawPlaceholder();
  }, []);

  const mergeImageWithCanvas = async (imageData: string) => {
    if (!imageData) return null;

    const canvas = document.createElement('canvas');

    const ctx = canvas.getContext('2d');

    if (ctx) {
      await new Promise((resolve) => {
        const image = new Image();

        image.onload = () => {
          canvas.width = image.width;
          canvas.height = image.height;

          ctx.drawImage(image, 0, 0);

          resolve(image);
        };
        image.src = imageData;
      });

      await new Promise((resolve) => {
        const image = new Image();
        image.style.opacity = '0.4';

        image.onload = () => {
          const imageWidth = image.width * 1.3;
          const imageHeight = image.height * 1.3;

          ctx.drawImage(image, canvas.width - imageWidth - 20, 20, imageWidth, imageHeight);
          resolve(image);
        };

        image.src = avantiaLogo;
      });

      ctx.fillStyle = 'rgba(80, 80, 80, 0.45)';

      const rectangleHeight = 70;

      ctx.fillRect(0, canvas.height - rectangleHeight, canvas.width, rectangleHeight);

      ctx.textAlign = 'center';
      ctx.fillStyle = 'white';
      ctx.font = ctx.font.replace(/\d+px/, '18px');

      ctx.fillText(`${text} Avantia™`, canvas.width / 2, canvas.height - (rectangleHeight / 2) + 8);

      if (infoType) {
        ctx.fillStyle = 'white';
        ctx.strokeStyle = 'rgb(80,74,74,0.5)';
        ctx.lineCap = 'round';
        ctx.lineJoin = 'round';
        ctx.lineWidth = 50;
        const margin = 40;

        if (infoType === 'amount') {
          const width = 270;
          ctx.strokeRect(margin, margin, width, 0);

          ctx.fillText(
            `Aglomeração de ${amounts[imageIndex]} ${amounts[imageIndex] === 1 ? detectionText.singular : detectionText.plural} detectada`,
            (width / 2) + margin,
            45,
          );
        }
        if (infoType === 'time') {
          const width = 270;
          ctx.strokeRect(margin, margin, width, 0);

          // ctx.fillText(
          //   `Permanência de ${
          //     amounts[imageIndex]
          //   } segundo${
          //     amounts[imageIndex] === 1 ? '' : 's'
          //   } detectada`,
          //   (width / 2) + margin,
          //   45,
          // );
        }
      }
    }

    const image = canvas.toDataURL('image/png');
    return image;
  };

  useEffect(() => {
    const mergeImage = async (targetImage: string) => {
      const image = await mergeImageWithCanvas(targetImage);
      if (image) {
        setMainImage(image);
      }
    };

    const targetImage = urlList[imageIndex];

    if (targetImage) {
      mergeImage(targetImage);
    }
  }, [imageIndex, urlList]);

  const { host } = window.location;

  const handleClick = async () => {
    try {
      if (!fileId && mainImage) {
        const formData = new FormData();
        const res = await fetch(mainImage);
        const blob = await res.blob();
        const file = new File([blob], 'send.png', blob);

        formData.set('media', file);
        const fileRes = await axiosPost({
          url: '/uploads',
          body: formData,
        });
        setFileId(fileRes.data.data.fileId);
      }
      setShareVisible(true);
    } catch (err) {
      console.log(err);
    }
  };

  const shareUrl = `https://${host}/livedemo/s/${fileId}`;

  const handlePassImage = (newIndex: number) => {
    if (newIndex >= 0 && newIndex < urlList.filter((u) => u).length) {
      setImageIndex(newIndex);
    }
  };

  return (
    <Modal
      visible={visible}
      title={(
        <>
          <TitleIcon src={warningSvg} />
          <Title>Detecções</Title>
        </>
      )}
      footer={null}
      centered
      onCancel={(e) => {
        e.stopPropagation();
        setVisible(false);
        deleteEverything();
        setMainImage('');
        setImageIndex(0);
      }}
    >
      <Description>
        Veja as últimas detecções dos seus analíticos
      </Description>
      <ImageWrapper>
        <ModalBigImage src={mainImage || placeholderImage} alt="Notificação em foco" />
        <ImageFooter>
          <span style={{ width: '35px' }} />
          <Timestamp>
            {`Hoje
            ${padTime(date.getHours())}:${padTime(date.getMinutes())}`}
          </Timestamp>
          <ShareModal
            visible={shareVisible}
            title="Compartilhe em:"
            footer={null}
            centered
            onCancel={(e) => {
              e.stopPropagation();
              setShareVisible(false);
            }}
          >
            <ShareFooterIcons closeModal={() => setShareVisible(false)} shareUrl={shareUrl} />
          </ShareModal>
          <ShareButton onClick={handleClick}>
            <ShareIcon src={shareSvg} />
          </ShareButton>
        </ImageFooter>
      </ImageWrapper>
      <ModalImageContainer>
        <ForwardButton onClick={() => handlePassImage(imageIndex + 1)}><RightOutlined style={{ color: 'black' }} /></ForwardButton>
        <BackwardsButton onClick={() => handlePassImage(imageIndex - 1)}><LeftOutlined style={{ color: 'black' }} /></BackwardsButton>
        <ModalImageWrapper>
          {urlList.map((url, i) => {
            if (!url) return <ModalPlaceholder />;

            return <ModalImage active={imageIndex === i} onClick={() => setImageIndex(i)} src={url} alt="Notificação" />;
          })}
        </ModalImageWrapper>
      </ModalImageContainer>
    </Modal>
  );
}

LastNotificationsModalMobile.defaultProps = {
  infoType: null,
};

export default LastNotificationsModalMobile;
