import React, {
  useState,
  createContext,
  useContext,
} from 'react';

import { Line } from 'components/organisms/Analytics';
import { OptionsConfig } from 'components/organisms/Options/Options';
import { SecondaryBackground } from 'components/atoms';
import { Options } from 'components/organisms';
import OptionsMobile from 'components/organisms/Options/Mobile';
import { useMobile } from 'hooks';
import lineSvg from 'assets/icons/analytics/crossing.svg';
import personSvg from 'assets/icons/person.svg';
import carSvg from 'assets/icons/car.svg';
import busSvg from 'assets/icons/bus.svg';
import truckSvg from 'assets/icons/truck.svg';
import LastNotificationsModalMobile from 'components/molecules/LastNotifications/Mobile';

type NotificationContextType = {
  notifications: (string | null)[],
  addNotification: (url: string, amount: number) => void,
  showNotifications: () => void,
}

const NotificationContext = createContext<NotificationContextType>({
  notifications: [null, null, null, null, null],
  addNotification: () => { },
  showNotifications: () => { },
});

function LineAnalytic() {
  const [options, setOptions] = useState<OptionsConfig>();
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const isMobile = useMobile(600);
  const [times, setTimes] = useState<(number | null)[]>([
    null, null, null, null, null,
  ]);
  const [amounts, setAmounts] = useState<(number | null)[]>([
    null, null, null, null, null,
  ]);
  const [notifications, setNotifications] = useState<(string | null)[]>([
    null, null, null, null, null,
  ]);

  const handleAddNotification = (url: string, amount: number) => {
    const notificationsCopy = [...notifications];
    notificationsCopy.pop();
    setNotifications([url, ...notificationsCopy]);

    const timesCopy = [...times];
    timesCopy.pop();
    setTimes([Date.now(), ...timesCopy]);

    const amountsCopy = [...amounts];
    amountsCopy.pop();
    setAmounts([amount, ...amountsCopy]);
  };

  const deleteEverything = () => {
    setTimes([null, null, null, null, null]);
    setAmounts([null, null, null, null, null]);
    setNotifications([null, null, null, null, null]);
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification: handleAddNotification,
        showNotifications: () => setShowNotifications(true),
      }}
    >
      <SecondaryBackground mobileHigher>
        {isMobile ? (
          <>
            <Line
              options={options}
            />
            <OptionsMobile
              notify={[
                { value: 'person', label: 'Pessoas', icon: personSvg },
                { value: 'car', label: 'Carros', icon: carSvg },
                { value: 'truck', label: 'Caminhões', icon: truckSvg },
                { value: 'bus', label: 'Ônibus', icon: busSvg },
              ]}
              showColorPicker
              onChange={setOptions}
              mobileHeight="24.7rem"
              analyticInfo={{
                name: 'Detecção de Cruzamento de Linha',
                description: 'Você está usando a tecnologia de detecção de cruzamento de linha da Avantia',
                icon: lineSvg,
              }}
            />
          </>
        ) : (
          <>
            <Line
              options={options}
            />
            <Options
              notify={[
                { value: 'person', label: 'Pessoas', icon: personSvg },
                { value: 'car', label: 'Carros', icon: carSvg },
                { value: 'truck', label: 'Caminhões', icon: truckSvg },
                { value: 'bus', label: 'Ônibus', icon: busSvg },
              ]}
              showColorPicker
              onChange={setOptions}
              analyticInfo={{
                name: 'Detecção de Cruzamento de Linha',
                description: 'Você está usando a tecnologia de detecção de cruzamento de linha da Avantia',
                icon: lineSvg,
              }}
            />
          </>
        )}
        <LastNotificationsModalMobile
          visible={showNotifications}
          setVisible={setShowNotifications}
          urlList={notifications}
          times={times}
          amounts={amounts}
          text="Tecnologia de Cruzamento de Linha"
          deleteEverything={deleteEverything}
          detectionText={{
            singular: 'elemento',
            plural: 'elementos',
          }}
        />
      </SecondaryBackground>
    </NotificationContext.Provider>
  );
}

export const useNotification = () => useContext(NotificationContext);

export default LineAnalytic;
