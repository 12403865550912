import styled, { css } from 'styled-components';
import { Checkbox as AntCheckbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import colors from 'styles/colors';

export const Card = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 0;
  color: black;
  font-size: .6rem;
  position: relative;
  overflow: hidden;

  .ant-checkbox-wrapper {
    color: black !important;
    font-size: .6rem !important;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-color: ${colors.deepBlue} !important;

    &::after {
      left: 25%;
    }
  }
  .ant-checkbox-wrapper-checked .ant-checkbox-inner {
    background-color: ${colors.deepBlue};
  }
  .ant-checkbox::after {
    border: 1px solid ${colors.deepBlue};
  }

  width: 100%;
  position: fixed;
  z-index: 5;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  bottom: 0;
  overflow: hidden;
  height: ${({ mobileHeight }: { mobileHeight: string, mobileShow: boolean }) => mobileHeight || '10rem'};
  max-height: 0;
  transition: max-height .8s ease-in-out, padding .4s linear, border .4s linear, transform .8s ease-in-out;

  ${({ mobileShow }) => mobileShow && css`
    max-height: ${({ mobileHeight }: { mobileHeight: string, mobileShow: boolean }) => mobileHeight || '10rem'};
    padding: 10px 0px 0px;
  `}
`;

export const Wrapper = styled.div`
  padding: 0 15px;
`;

export const TitleContainer = styled.button`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0 0 15px;
`;

export const Dash = styled.div`
  height: 3px;
  width: 37px;
  background-color: #dbdbdb;
  border-radius: 50px;
  margin: 0 0 5px;
`;

export const Title = styled.h3`
  color: #333;
  text-transform: uppercase;
  font-size: 15px;
`;

export const Description = styled.h3`
  text-transform: uppercase;
  color: #707070;
  font-size: .5rem;
`;

export const Section = styled.div`
  margin-bottom: 8px;
`;

export const Label = styled.h3`
  color: #4e4e4e;
  font-size: 12px;
  font-weight: bolder;
  margin: 0;
`;

export const TrashButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const TrashIcon = styled.img`
  height: 23px;
  margin: 1px 10px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
`;

export const Button = styled.button<{ clean?: boolean, save?: boolean }>`
  border: none;
  outline: none;
  padding: 5px 20px;
  color: black;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: .7rem;
  cursor: pointer;

  ${({ clean }) => clean && css`
    background-color: #DADADA;
    color: #707070;
  `}

  ${({ save }) => save && css`
    background-color: ${colors.deepBlue};
    color: white;
  `}
`;

export const Input = styled.input`
  text-align: center;
  background-color: ${colors.lightGray};
  border: none;
  width: 4rem;
  font-size: .7rem;
  margin: 0 5px 0 0;
  outline: none;
  border-bottom: 2px solid ${colors.secondaryGray};
`;

export const ColorsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ColorBall = styled.button`
  width: 15px;
  height: 15px;
  background-color: ${({ color }: { color: string, border?: boolean, active?: boolean }) => color || 'white'};
  border-radius: 50px;
  outline: none;
  margin: 5px;
  padding: 0;
  border: ${({ border }) => (border ? '1px solid #B2B2B2' : 'none')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ active }) => (active && css`
    border: 1px solid black;
  `)}
`;

export const PlusIcon = styled(PlusOutlined)`
  color: #B2B2B2;
`;

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
`;

export const Shadow = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const DropdownOpen = styled.button`
  height: 1rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-color: white;
  border: none;
  outline: none;

  @media only screen and (max-width: 450px) {
    position: absolute;
    bottom: 0;
  }
`;

export const DropdownClose = styled.button`
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-color: white;
  border: none;
  outline: none;
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: ${({ progress }: { progress: number }) => {
    if (progress === -20 || progress === -40) return '0%';
    return (progress ? `${progress}%` : '0%');
  }};
  height: 3px;
  background-color: ${colors.blue};

  ${({ progress }) => {
    if (progress !== 100 && progress !== -20) {
      return css`transition: max-width 1s linear;`;
    }
    return '';
  }}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CheckboxRow = styled.div`
  display: flex;
  width: 100%;
  margin: 7px 0;
`;

export const Icon = styled.img`
  width: 15px;
  margin: 0 10px;
`;

export const CheckboxParagraph = styled.p`
  margin: 0;
  color: #4e4e4e;
  width: 100%;
  font-size: 12px;
`;

export const Checkbox = styled(AntCheckbox)`
  margin: 0 10px;
`;

export const SliderCurrentVal = styled.p`
  width: 100%;
  color: #d5070a;
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  margin: 0;
  margin-bottom: -15px;
  pointer-events: none;
`;

export const Dark = styled.div`
  background-color: transparent;
  position: fixed;
  z-index: 4;
  width: 100%;
  height: 100vh;
`;
