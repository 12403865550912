import React, { useState } from 'react';
import { notification } from 'antd';
import { Redirect } from 'react-router-dom';

import { Button } from 'components/atoms';
import { Title } from 'components/atoms/Text';
import arrowSvg from 'assets/icons/arrow.svg';
import feedbackSvg from 'assets/icons/feedback.svg';
import timerSvg from 'assets/icons/timer.svg';
import shareSvg from 'assets/icons/share.svg';
import lockSvg from 'assets/icons/lock.svg';

import {
  AccessBackground,
  TextContainer,
  AccessIcon,
  AccessItemContainer,
  AccessItem,
  Image,
  AccessText,
  BottomText,
} from './AccessCard.style';
import accessItems from './AccessItems';
import AccessCardMobile from './Mobile';

function AccessCard({ mobile }: { mobile?: boolean }) {
  const [redirect, setRedirect] = useState('');
  const [errorMessageDisplayed, setErrorMessageDisplayed] = useState(false);

  if (mobile) return <AccessCardMobile />;

  const requestAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          facingMode: 'user',
          width: 640,
          height: 480,
        },
      });

      // @ts-ignore
      navigator.mediaStream = stream;

      setRedirect('/livedemo/escolha');
    } catch (err) {
      if (!errorMessageDisplayed) {
        notification.error({
          message: 'Sem acesso à câmera',
          description:
            'Verifique na barra do navegador se há uma solicitação ou um ícone de câmera',
        });
        setErrorMessageDisplayed(true);
      }
    }
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <AccessBackground>
      <TextContainer>
        <Title bold color="orange">
          Live Demo
        </Title>
        <AccessItemContainer>
          {accessItems.map((item, i) => (
            <AccessItem>
              <Image src={[timerSvg, shareSvg, feedbackSvg][i]} />
              <AccessText>{item}</AccessText>
            </AccessItem>
          ))}
        </AccessItemContainer>
        <Button
          className="small"
          style={{ color: 'white' }}
          size="small"
          type="primary"
          onClick={requestAccess}
        >
          Ir para o teste
          <AccessIcon button src={arrowSvg} />
        </Button>
        <BottomText color="white">
          <Image src={lockSvg} />
          <p>
            Sua privacidade é garantida. Estamos alinhados às normativas da nova
            Lei Geral de Proteção de Dados (LGPD).
          </p>
        </BottomText>
      </TextContainer>
    </AccessBackground>
  );
}

AccessCard.defaultProps = {
  mobile: false,
};

export default AccessCard;
