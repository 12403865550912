import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { flex, flexcc } from 'styles/variables';

const backgroundColor = '#f1f1f1';

export const Container = styled.ul`
  ${flex('flex-end', 'center', 'row', 'nowrap')}
  width: 100%;
  position: relative;
  padding: 0;
  min-height: 120px;
  margin: 0;
  overflow-x: auto;
  background-color: ${backgroundColor};
  user-select: none;
  overflow-x: hidden;
`;

export const LinkContainer = styled.div<{ margin: number }>`
  ${flex('flex-end', 'center', 'row', 'nowrap')}
  margin: 0 ${({ margin }) => (margin ? `${margin}px` : '0px')};
  width: 100vw;
`;

export const CustomLink = styled(Link)<{ margin: number, width: number }>`
  ${flexcc}
  position: relative;
  z-index: 1;
  height: ${({ width }) => (width ? `${width}px` : '0px')};
  width: ${({ width }) => (width ? `${width}px` : '0px')};
  margin: 0 ${({ margin }) => (margin ? `${margin / 2}px` : '0px')};
  overflow: hidden;

  &:first-child { margin-left: 0; };
  &:nth-last-child(1) { margin-right: 0; };
`;

export const Card = styled.li<{ active: boolean }>`
  ${flex('center', 'center', 'column', 'nowrap')}
  list-style: none;
  border-radius: 50px;
  padding: 10px;
  width: 100%;
  height: 100%;
  position:  absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: box-shadow .5s ease-in-out, background-color .5s ease-in-out, filter .5s ease-in-out;
  background-color: white;

  img {
    filter: invert(63%) sepia(9%) saturate(446%) hue-rotate(188deg) brightness(87%) contrast(83%);
    transition: filter .5s ease-in-out;
  }

  ${({ active }) => {
    if (active) {
      return css`
        img {
          filter: unset;
        }
      `;
    }
    return null;
  }}


  p {
    transition: color .5s ease-in-out;
    font-size: .6rem;
    text-transform: capitalize !important;
    text-align: center;
  }
`;

export const SelectionRingWrapper = styled.div`
  width: 49px;
  height: 49px;
  background-image: linear-gradient(132deg, #7dceff -24%, #3f83fe 13%, #82d3ff 102%);
  background-color: transparent;
  border-radius: 50px;
  ${flexcc}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SelectionRing = styled.div`
  width: 90%;
  height: 90%;
  background-color: ${backgroundColor};
  border-radius: 50px;
`;

export const Blank = styled.div<{ maxWidth: number }>`
  height: 1px;
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '0px')};
  transition: max-width .3s ease-in-out;
`;

export const Dash = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  margin: 0 0 7.4px;
  background-image: linear-gradient(to right, #7dceff 0%, #3f83fe 29%, #82d3ff 98%);
`;

export const NameContainer = styled.button`
  ${flexcc}
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;
`;

export const Name = styled.p`
  text-align: center;
  color: #0c0d34;
  font-size: 12px;
  margin: 0;
`;
