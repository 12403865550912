import React from 'react';

import {
  InfoContainer,
  ImageContainer,
  Image,
  TextContainer,
  Name,
  Desc,
} from './AnalyticInfo.style';

function AnalyticInfo({
  analyticInfo,
}: {
  analyticInfo?: { name: string, description: string, icon: string },
}) {
  return (
    <InfoContainer>
      <ImageContainer>
        <Image src={analyticInfo?.icon} />
      </ImageContainer>
      <TextContainer>
        <Name>{analyticInfo?.name}</Name>
        <Desc>{analyticInfo?.description}</Desc>
      </TextContainer>
    </InfoContainer>
  );
}

AnalyticInfo.defaultProps = {
  analyticInfo: {
    name: '',
    description: '',
    icon: '',
  },
};

export default AnalyticInfo;
