import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import intersects from 'intersects';

import {
  DetectionLink,
  SwitchCam,
  TimeCounter,
  UndoButton,
} from 'components/atoms';
import { useInfo } from 'contexts/GlobalProvider';
import { useNotification } from 'components/pages/Time';

import {
  StyledCanvas,
  CanvasContainer,
} from './TimeCanvas.style';

type TimeCanvasProps = {
  dimensions: number[],
  detections: ServerResponse[] | undefined,
  color?: string,
  limitTime?: number,
  clear: boolean,
  options: any,
  switchCam: () => void,
};

function TimeCanvas({
  dimensions,
  detections,
  color = '#4BBFD1',
  limitTime,
  clear,
  options,
  switchCam,
}: TimeCanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [dots, setDots] = useState<number[][]>([]);
  const [entities, setEntities] = useState<ServerResponse[]>([]);
  const [mouseDown, setMouseDown] = useState<boolean>(false);
  const [autoShape, setAutoShape] = useState<boolean>(true);
  const [info, setInfo] = useInfo();
  const dotGrabbed = useRef<number | null>(null);
  const { addNotification, showNotifications, notifications } = useNotification();
  const [remainingTime, setRemainingTime] = useState(0);
  const remainingTimeRef = useRef(0);
  const [, setCounterTimeout] = useState(0);
  const lockDetection = useRef(false);

  const entityLabels = useRef<string[]>([]);

  const undo = () => {
    setDots((prevDots) => {
      if (prevDots.length === 1) return [];
      const tempDots = [...prevDots];

      tempDots.pop();

      return tempDots;
    });
  };

  const drawImageOnVideoSync = useCallback((canvas: HTMLCanvasElement) => {
    const drawCanvas = canvas;

    drawCanvas.width = info.video.videoWidth;
    drawCanvas.height = info.video.videoHeight;

    const ctx = drawCanvas.getContext('2d') as CanvasRenderingContext2D;

    if (info.invert) {
      ctx.translate(drawCanvas.width, 0);
      ctx.scale(-1, 1);
    }
    ctx.drawImage(info.video, 0, 0);
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.drawImage(info.canvas, 0, 0);

    const image = drawCanvas.toDataURL('image/png');
    return image;
  }, [info]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.addEventListener('click', (e) => {
        const rect = canvas.getBoundingClientRect();
        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        setDots((prevDots: number[][]) => {
          const dot = [
            (e.clientX - rect.left) * scaleX,
            (e.clientY - rect.top) * scaleY,
          ];

          let isMouseOver = false;

          prevDots.forEach(([x, y]) => {
            if (
              Math.abs(dot[0] - x) < 10
              && Math.abs(dot[1] - y) < 10
            ) {
              isMouseOver = true;
            }
          });

          if (isMouseOver) return prevDots;
          return ([
            ...prevDots,
            dot,
          ]);
        });
      });
    }
  }, [canvasRef]);

  useEffect(() => {
    window.addEventListener('keypress', (event) => {
      if (event.key === 'z' && event.ctrlKey) {
        event.preventDefault();
        undo();
      }
    });
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) return;

    const ctx = canvas.getContext('2d');

    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    ctx.lineWidth = 5;

    dots.forEach((dot, i) => {
      ctx.beginPath();
      ctx.arc(dot[0], dot[1], 5, 0, Math.PI * 2);
      ctx.fill();

      if (i === 0) return;

      ctx.moveTo(dot[0], dot[1]);
      ctx.lineTo(dots[i - 1][0], dots[i - 1][1]);
      ctx.stroke();

      if (i !== dots.length - 1) return;

      ctx.moveTo(dot[0], dot[1]);
      ctx.lineTo(dots[0][0], dots[0][1]);
      ctx.stroke();
    });

    if (dots[0]) {
      ctx.fillStyle = `${color}33`;
      ctx.beginPath();
      ctx.moveTo(dots[0][0], dots[0][1]);
      for (let i = 0; i < dots.length; i += 1) {
        const dot = dots[i];
        ctx.lineTo(dot[0], dot[1]);
      }
      ctx.closePath();
      ctx.fill();
    }

    ctx.fillStyle = color;
  }, [dots, color]);

  useEffect(() => {
    if (dots.length > 0 && autoShape) {
      setAutoShape(false);
    }
    if (dots.length === 0 && !autoShape) {
      setAutoShape(true);
    }
  }, [dots, setAutoShape, autoShape]);

  useEffect(() => {
    if (detections && autoShape) {
      setEntities(detections);
      return;
    }
    if (detections && dots.length > 2) {
      const entitiesInside = detections.filter((detection) => {
        const detectionDots: number[] = JSON.parse(detection.bb_o);

        const width = detectionDots[2];
        const height = detectionDots[3];

        const intersect = intersects.ellipsePolygon(
          detectionDots[0] + width / 2,
          detectionDots[1] + height / 2,
          width / 3,
          height / 3,
          dots.flat(),
        );

        return intersect;
      });
      setEntities(entitiesInside);
    } else if (dots.length <= 2) {
      setEntities([]);
    }
  }, [dots, JSON.stringify(detections)]);

  useEffect(() => {
    if (canvasRef.current) {
      setInfo((prevInfo: Object) => ({
        ...prevInfo,
        canvas: canvasRef.current,
      }));
    }
    return () => {
      setInfo((prevInfo: Object) => ({
        ...prevInfo,
        canvas: null,
      }));
    };
  }, [canvasRef, setInfo]);

  useEffect(() => {
    lockDetection.current = false;
  }, [options.time]);

  useEffect(() => {
    if (
      info.video
      && info.canvas
      && entities.length > 0
    ) {
      if (
        JSON.stringify(entityLabels.current)
        !== JSON.stringify(entities.map(({ label }) => label))
      ) {
        if (lockDetection.current) lockDetection.current = false;
        setRemainingTime(options.time);
        entityLabels.current = entities.map(({ label }) => label);
      }

      if (!lockDetection.current) {
        setCounterTimeout((prevTimeout) => {
          if (prevTimeout) clearTimeout(prevTimeout);

          return setTimeout(() => {
            if (
              remainingTimeRef.current <= 1
              && lockDetection.current === false
            ) {
              lockDetection.current = true;
              addNotification(drawImageOnVideoSync(document.createElement('canvas')), options.time * 1000);
            }
          }, options.time * 1000);
        });
      }
    }
  }, [JSON.stringify(entities), limitTime, info.video, info.canvas, options.time]);

  useEffect(() => {
    if (clear) setDots([]);
  }, [clear, options]);

  const watchCanvas = useCallback((event: any) => {
    const e = event as MouseEvent;
    const canvas = canvasRef.current;
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;

      const dot = [
        (e.clientX - rect.left) * scaleX,
        (e.clientY - rect.top) * scaleY,
      ];

      let mouseOverIndex = -1;

      const isGrabbing = document.body.style.cursor === 'grabbing';

      if (isGrabbing) {
        setDots((prevDots: number[][]) => {
          const tempDots = [...prevDots];

          if (dotGrabbed.current !== null) {
            tempDots[dotGrabbed.current] = dot;
            return tempDots;
          }

          return prevDots;
        });
        return;
      }

      dots.forEach(([x, y], i) => {
        if (
          Math.abs(dot[0] - x) < 10
          && Math.abs(dot[1] - y) < 10
        ) mouseOverIndex = i;
      });

      document.body.style.cursor = mouseOverIndex !== -1 ? 'grab' : 'auto';

      if (mouseOverIndex !== -1 && mouseDown) {
        document.body.style.cursor = 'grabbing';
        dotGrabbed.current = mouseOverIndex;

        setDots((prevDots: number[][]) => {
          const tempDots = [...prevDots];

          tempDots[mouseOverIndex] = dot;

          return tempDots;
        });
      }
    }
  }, [canvasRef, dots, mouseDown]);

  useEffect(() => {
    let timeout = 0;
    if (remainingTime) {
      timeout = setTimeout(() => {
        setRemainingTime(remainingTime - 1);
        remainingTimeRef.current = remainingTime - 1;
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [remainingTime]);

  const handleMouseDown = () => setMouseDown(true);

  const handleMouseUp = () => {
    setMouseDown(false);
    dotGrabbed.current = null;
    document.body.style.cursor = 'auto';
  };

  const lookToWidth = dimensions[0]
    > (
      containerRef.current
        ? parseFloat(getComputedStyle(containerRef.current).width)
        : window.innerWidth
    );

  return (
    <CanvasContainer ref={containerRef}>
      <StyledCanvas
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={watchCanvas}
        width={dimensions[1]}
        height={dimensions[0]}
        $lookToWidth={lookToWidth}
      />
      <UndoButton onClick={undo} />
      <SwitchCam onClick={switchCam} />
      <DetectionLink showNotifications={showNotifications} factor={notifications} detection={notifications.filter((n) => n).length > 0} text="Permanência Detectada" />
      <TimeCounter time={`00:${remainingTime.toString().length > 1 ? remainingTime : `0${remainingTime}`}`} />
    </CanvasContainer>
  );
}

export interface ServerResponse {
  'bb_o': string;
  prob: number;
  label: 'person' | 'truck' | 'car' | 'bus';
}

export default TimeCanvas;
