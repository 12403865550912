import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { Redirect } from 'react-router-dom';

import { Loader } from 'components/atoms';
import { OptionsConfig } from 'components/organisms/Options/Options';
import { Video, TimeCanvas } from 'components/molecules';
import { useAxios } from 'global/func';
import { useInfo } from 'contexts/GlobalProvider';
import imageToBlob from 'helpers/imageToBlob';
import { useMobile, useWindowResize } from 'hooks';

import { Container } from '../Analytic.style';
import { Tutorial } from './Tutorials';
import getContainerWidth from './helpers/getContainerWidth';

function Time({
  options,
}: {
  options?: OptionsConfig,
}) {
  const [dimensions, setDimensions] = useState<number[]>([]);
  const [videoElement, setVideoElement] = useState<HTMLVideoElement>();
  const [detections, setDetections] = useState<ServerResponse[]>();
  const [redirect, setRedirect] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const req = useRef(true);
  useWindowResize();
  const [facingMode, setFacingMode] = useState<('user' | 'environment')>('user');
  const switchCam = () => setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  const isMobile = useMobile();

  const [axiosPost] = useAxios('post');

  const inverted = useRef(true);
  const [info, setInfo] = useInfo();

  const { invert } = info;

  useEffect(() => {
    inverted.current = invert;
  }, [invert]);

  const handleDimensions = useCallback((values: number[], video: HTMLVideoElement) => {
    setDimensions(values);
    setVideoElement(video);
  }, [setDimensions, setVideoElement]);

  useEffect(() => {
    const sendImage = async () => {
      try {
        const blob = await imageToBlob(videoElement, inverted.current);
        const formData = new FormData();

        formData.set('image', blob);

        const before = Date.now();

        const res = await new Promise<any>((resolve) => {
          (async () => {
            const axiosRes = await axiosPost({
              url: '/common',
              body: formData,
            });
            resolve(axiosRes);
          })();
          setTimeout(() => {
            resolve(null);
          }, 4000);
        });

        if (!res) {
          sendImage();
          return;
        }

        const serverResponse: ServerResponse[] = res.data.data;
        const { expiringDate } = res.data;

        setDetections(serverResponse);
        setInfo((prevInfo: any) => ({
          ...prevInfo,
          latency: Date.now() - before,
          ...(prevInfo.expiringDate ? {} : { expiringDate }),
        }));
        if (req.current) sendImage();
      } catch (err) {
        console.log(err);
        if (err.response?.status === 403) {
          setRedirect('/livedemo/expired');
        } else if (req.current) sendImage();
      }
    };
    sendImage();
  }, [videoElement, setInfo, axiosPost]);

  useEffect(() => () => {
    req.current = false;
  }, []);

  if (redirect) return <Redirect to={redirect} />;

  return (
    <Container
      ref={containerRef}
      className="video-container"
      {...getContainerWidth(containerRef, dimensions, isMobile || false)}
    >
      <Video facingMode={facingMode} getDimensions={handleDimensions} />
      <Tutorial type="time">
        {dimensions.length > 0 ? (
          <TimeCanvas
            color={options?.color}
            limitTime={options?.time}
            detections={detections?.filter(({ label }) => (
              options?.notify.includes(label)
            )) || []}
            clear={options?.default || false}
            options={options}
            switchCam={switchCam}
            dimensions={[info.video?.videoHeight, info.video?.videoWidth]}
          />
        ) : <Loader width={`${dimensions[1] || 0}px`} height={`${dimensions[0] || 0}px`} />}
      </Tutorial>
    </Container>
  );
}

Time.defaultProps = {
  options: {
    quantity: 0,
  },
};

export interface ServerResponse {
  'bb_o': string;
  prob: number;
  label: 'person' | 'truck' | 'car' | 'bus';
}

export default Time;
