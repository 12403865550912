import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { Redirect } from 'react-router-dom';

import { Loader } from 'components/atoms';
import { OptionsConfig } from 'components/organisms/Options/Options';
import { Video, CrowdingCanvas } from 'components/molecules';
import { useAxios } from 'global/func';
import { useInfo } from 'contexts/GlobalProvider';
import { useMobile, useWindowResize } from 'hooks';
import imageToBlob from 'helpers/imageToBlob';

import { Container } from '../Analytic.style';
import { Tutorial } from './Tutorials';
import getContainerWidth from './helpers/getContainerWidth';

function Crowding({
  options,
}: {
  options?: OptionsConfig,
}) {
  const [dimensions, setDimensions] = useState<number[]>([]);
  const [videoElement, setVideoElement] = useState<HTMLVideoElement>();
  const [detections, setDetections] = useState<ServerResponse[]>();
  const [redirect, setRedirect] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const req = useRef(true);
  useWindowResize();
  const [facingMode, setFacingMode] = useState<('user' | 'environment')>('user');
  const switchCam = () => setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
  const isMobile = useMobile();

  const connectionInfoArr: ({
    imageMounting?: number,
    backend?: number,
    neuralNetwork?: number,
    error?: boolean,
    timeStart: number,
    timeEnd: number,
  })[] = [];
  const reqLimit = 100;
  let downloadMessageShowed = false;
  const downloadRequestData = false;

  const [axiosPost] = useAxios('post');

  const inverted = useRef(true);
  const [info, setInfo] = useInfo();

  const { invert } = info;

  useEffect(() => {
    inverted.current = invert;
  }, [invert]);

  const handleDimensions = useCallback((values: number[], video: HTMLVideoElement) => {
    setDimensions(values);
    setVideoElement(video);
  }, [setDimensions, setVideoElement]);

  useEffect(() => {
    const sendImage = async () => {
      try {
        const first = Date.now();
        const blob = await imageToBlob(videoElement, inverted.current);
        const afterImage = Date.now();
        const formData = new FormData();

        formData.set('image', blob);

        const beforeBackend = Date.now();

        const res = await new Promise<any>((resolve) => {
          (async () => {
            try {
              const axiosRes = await axiosPost({
                url: '/common',
                body: formData,
              });

              if (!downloadRequestData) {
                resolve(axiosRes);
                return;
              }

              if (connectionInfoArr.length < reqLimit) {
                connectionInfoArr.push({
                  imageMounting: afterImage - first,
                  backend: (Date.now() - beforeBackend) - axiosRes.data.neuralNetLatency,
                  neuralNetwork: axiosRes.data.neuralNetLatency,
                  timeStart: beforeBackend,
                  timeEnd: Date.now(),
                });

                console.log(`${connectionInfoArr.length}/${reqLimit}`);
              }
              if (connectionInfoArr.length === reqLimit) {
                if (!downloadMessageShowed) {
                  const response = window.confirm('Coleta terminada, deseja fazer o download dos dados?');
                  downloadMessageShowed = true;
                  if (response) {
                    const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(connectionInfoArr))}`;
                    const a = document.createElement('a');
                    a.setAttribute('href', dataStr);
                    a.setAttribute('download', 'data.json');
                    const projectRoot = document.querySelector('#root');
                    if (projectRoot) {
                      projectRoot.appendChild(a);
                      a.click();
                      projectRoot.removeChild(a);
                    }
                  }
                }
              }

              resolve(axiosRes);
            } catch (err) {
              if (!downloadRequestData) return;
              if (connectionInfoArr.length < reqLimit) {
                connectionInfoArr.push({
                  error: true,
                  imageMounting: afterImage - first,
                  timeStart: beforeBackend,
                  timeEnd: Date.now(),
                });

                console.log(`${connectionInfoArr.length}/${reqLimit} (error)`);
              }
            }
          })();
          setTimeout(() => {
            resolve(null);
          }, 4000);
        });

        if (!res) {
          sendImage();
          return;
        }

        const serverResponse: ServerResponse[] = res.data.data;
        const { expiringDate } = res.data;

        setDetections(serverResponse);
        setInfo((prevInfo: any) => ({
          ...prevInfo,
          latency: Date.now() - beforeBackend,
          ...(prevInfo.expiringDate ? {} : { expiringDate }),
        }));
        if (req.current) sendImage();
      } catch (err) {
        console.log(err);
        if (err.response?.status === 403) {
          setRedirect('/livedemo/expired');
        } else if (req.current) sendImage();
      }
    };
    sendImage();
  }, [videoElement, setInfo, axiosPost]);

  useEffect(() => () => {
    req.current = false;
  }, []);

  if (redirect) return <Redirect to={redirect} />;

  return (
    <Container
      ref={containerRef}
      className="video-container"
      {...getContainerWidth(containerRef, dimensions, isMobile || false)}
    >
      <Video facingMode={facingMode} getDimensions={handleDimensions} />
      <Tutorial type="crowding">
        {dimensions.length > 0 ? (
          <CrowdingCanvas
            color={options?.color}
            threshold={options?.quantity || 0}
            detections={detections?.filter(({ label }) => (
              options?.notify?.includes(label)
            )) || []}
            clear={options?.default || false}
            options={options}
            switchCam={switchCam}
            dimensions={[info.video?.videoHeight, info.video?.videoWidth]}
          />
        ) : <Loader height={`${dimensions[0] || 0}px`} width={`${dimensions[1] || 0}px`} />}
      </Tutorial>
    </Container>
  );
}

Crowding.defaultProps = {
  options: {
    quantity: 0,
  },
};

export interface ServerResponse {
  'bb_o': string;
  prob: number;
  label: 'person' | 'truck' | 'car' | 'bus';
}

export default Crowding;
