import React, { useState, useEffect, useRef } from 'react';
import { notification } from 'antd';

import { useInfo } from 'contexts/GlobalProvider';

import { ReactComponent as ChromeSVG } from 'assets/BowserIcons/icons8-chrome.svg';
import { ReactComponent as EdgeSVG } from 'assets/BowserIcons/icons8-microsoft-edge.svg';
import { ReactComponent as FirefoxSVG } from 'assets/BowserIcons/firefox-logo.svg';
import { ReactComponent as BraveSVG } from 'assets/BowserIcons/icons8-brave-web-browser.svg';
import { useMobile, useVideoHeight } from 'hooks';

import { VideoDisplay, BrowserContainer } from './Video.style';

function Video({
  getDimensions,
  facingMode,
}: {
  getDimensions: Function;
  facingMode: 'user' | 'environment';
}) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [errorMessageDisplayed, setErrorMessageDisplayed] = useState(false);
  const [invert, setInvert] = useState(false);
  const [, setInfo] = useInfo();
  const isMobile = useMobile();
  const preferredVideoHeight = useVideoHeight();

  const browserIncompatibilityMessage = (
    <p>
      Recomendamos o uso dos seguintes navegadores:
      <BrowserContainer>
        <ChromeSVG />
        <EdgeSVG />
        <FirefoxSVG height="45px" />
        <BraveSVG />
      </BrowserContainer>
    </p>
  );

  useEffect(() => {
    const getUserMedia = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();

        const videoDevices = devices.filter(({ kind }) => kind === 'videoinput');

        const videoDevice = videoDevices.find(
          ({ label }) => label.includes(facingMode === 'user' ? 'facing front' : 'facing back')
          // eslint-disable-next-line function-paren-newline
        );

        let videoDeviceId = videoDevice?.deviceId || videoDevices[0].deviceId;

        if (
          [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod',
          ].includes(navigator.platform)
        ) {
          if (facingMode !== 'user') {
            videoDeviceId = videoDevice?.deviceId || videoDevices[1].deviceId;
          }
        }

        // @ts-ignore
        if (navigator.mediaStream) {
          // @ts-ignore
          navigator.mediaStream.getTracks().forEach((track) => {
            track.stop();
          });
        }

        // @ts-ignore
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: {
            // facingMode: { exact: facingMode },
            ...(videoDeviceId && { deviceId: { exact: videoDeviceId } }),
            ...(isMobile
              ? {
                  width: 480,
                  height: 600,
                }
              : {
                  width: 1280,
                  height: 720,
                }),
          },
        });

        if (videoRef.current) {
          const video = videoRef.current;
          const videoHeight = isMobile ? 600 : preferredVideoHeight;
          video.srcObject = stream;
          video.play();
          video.onloadedmetadata = () => {
            getDimensions(
              [videoHeight, (videoHeight * video.videoWidth) / video.videoHeight],
              video
            );
          };
        }
        if (
          [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod',
          ].includes(navigator.platform)
        ) {
          if (!errorMessageDisplayed) {
            notification.warn({
              message: 'Experienciando problemas?',
              description: browserIncompatibilityMessage,
              duration: 10000,
            });
            setErrorMessageDisplayed(true);
          }
        }

        setInfo((prevInfo: Object) => ({
          ...prevInfo,
          stream,
          invert: facingMode === 'user',
        }));
        setInvert(facingMode === 'user');
        // @ts-ignore
        navigator.mediaStream = stream;
      } catch (err) {
        console.log(err);
        notification.error({
          message: 'Sem acesso à câmera',
          description:
            'Verifique na barra do navegador se há uma solicitação ou um ícone de câmera',
        });
      }
    };

    getUserMedia();
  }, [getDimensions, setInfo, facingMode]);

  useEffect(() => {
    if (videoRef.current) {
      setInfo((prevInfo: Object) => ({
        ...prevInfo,
        video: videoRef.current,
      }));
    }
    return () => {
      setInfo((prevInfo: Object) => ({
        ...prevInfo,
        video: null,
      }));
    };
  }, [videoRef, setInfo]);

  return (
    <VideoDisplay
      invert={invert}
      ref={(ref) => {
        // @ts-ignore
        const [video] = [...(ref?.children || [])];
        // @ts-ignore
        videoRef.current = video;
      }}
      dangerouslySetInnerHTML={{
        __html: `
          <video muted playsinline></video>
        `,
      }}
    />
  );
}

export default Video;
