import styled, { css } from 'styled-components';

import { flexcc } from 'styles/variables';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
  max-width: ${({ $open }: { $open: boolean }) => ($open ? '320px' : '35px')};
  transition: max-width 1s ease-in-out;
  width: max-content;
  height: 35px;
  border-radius: 50px;
  backdrop-filter: blur(9px) brightness(0.7);
  background-color: rgb(80, 74, 74, 0.25);

  & > div {
    margin-right: 45px;
  }
`;

export const TextWrapper = styled.div`
  width: calc(100% - 35px);
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
`;

export const Button = styled.button<{ $clickable: boolean, $open: boolean }>`
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 50px;
  outline: none;
  ${flexcc}
  background-color: #b5b5b5;
  transition: background-color .5s ease-in-out;
  cursor: ${({ $clickable }) => $clickable && 'pointer'};

  ${({ $open }) => $open && css`
    img {
      width: 17.3px;
    }
  `}
  ${({ $open }) => !$open && css`
    background-color: rgb(80, 74, 74, 0);
  `}
`;

export const DetectionText = styled.p`
  font-size: 12px;
  margin: 0;
`;

export const LinkButton = styled.button`
  font-size: 12px;
  margin: 0;
  text-decoration: underline;
  background-color: transparent;
  font-weight: 600;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
`;

export const InsideIcon = styled.img`
  width: 15px;
  margin: 0 13px 0 15px;
`;

export const Icon = styled.img`
  width: 15px;
`;

export const AlertBall = styled.div`
  /* width: 17px; */
  padding: 0 5px;
  height: 17px;
  background-color: #d5070a;
  border-radius: 50px;
  position: absolute;
  top: -5px;
  left: calc(100% - 10px);
  pointer-events: none;
  font-size: 11px;
  line-height: 18px;

  ${flexcc}
`;
