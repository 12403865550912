import React from 'react';

import selectionGif from 'assets/selection.gif';
import cogIcon from 'assets/icons/cog-white.svg';

export default {
  crowding: (isMobile: boolean | null) => (
    <>
      <p>
        <span>Clique</span>
        {' '}
        na tela para especificar uma área de detecção.
      </p>
      <img src={selectionGif} alt="Gif de seleção" />
      <p>
        <span>Personalize</span>
        {' '}
        {isMobile ? (
          <>
            suas detecções no menu “opções” (seta ao lado do analítico).
          </>
        ) : (
          <>
            suas detecções nas configurações ao lado.
            (
            <img className="config" src={cogIcon} alt="Ícone de Configuração" />
            )
          </>
        )}
      </p>
      <p>
        <span>Escolha</span>
        {' '}
        a partir de quantas pessoas presentes o analítico deverá alertar
        uma aglomeração (a configuração padrão conta a partir de uma pessoa).
      </p>
    </>
  ),
  line: (isMobile: boolean | null) => (
    <>
      <p>
        <span>Clique</span>
        {' '}
        {isMobile ? (
          <>
            na tela para criar um novo ponto e mudar
            a posição da linha que aparece automaticamente.
          </>
        ) : (
          <>
            na tela para mudar a posição da linha.
          </>
        )}
      </p>
      <img src={selectionGif} alt="Gif de seleção" />
      <p>
        <span>
          Personalize
        </span>
        {' '}
        {isMobile ? (
          <>
            suas detecções no menu “opções” (seta ao lado do analítico).
          </>
        ) : (
          <>
            suas detecções nas configurações ao lado.
            (
            <img className="config" src={cogIcon} alt="Ícone de Configuração" />
            )
          </>
        )}
      </p>
    </>
  ),
  time: (isMobile: boolean | null) => (
    <>
      <p>
        <span>
          Clique
        </span>
        {' '}
        na tela para especificar uma área de detecção.
      </p>
      <img src={selectionGif} alt="Gif de seleção" />
      <p>
        <span>
          Personalize
        </span>
        {' '}
        {isMobile ? (
          <>
            suas detecções no menu “opções” (seta ao lado do analítico).
          </>
        ) : (
          <>
            suas detecções nas configurações ao lado.
            (
            <img className="config" src={cogIcon} alt="Ícone de Configuração" />
            )
          </>
        )}
      </p>
      <p>
        <span>
          Escolha
        </span>
        {' '}
        a partir de quantos segundos o analítico deverá alertar
        uma detecção de tempo de permanência na cena (a configuração
        padrão detecta a partir de dois segundos).
      </p>
    </>
  ),
};
