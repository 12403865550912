import styled, { css } from 'styled-components';
import { Checkbox as AntCheckbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import colors from 'styles/colors';

export const Card = styled.div<{ $active: boolean, $marginRight: number }>`
  position: fixed;
  top: 50%;
  right: ${({ $marginRight }) => ($marginRight ? `${$marginRight}px` : '0')};
  background-color: white;
  border-radius: 5px;
  padding: 0;
  color: black;
  font-size: .6rem;
  z-index: 5;
  overflow: hidden;
  width: 340px;
  transform: translateY(-50%) ${({ $active }) => ($active ? 'scaleX(1)' : 'scaleX(0)')};
  transform-origin: 100% 0;
  box-shadow: -3px 3px 6px 0 rgba(0, 0, 0, 0.18);
  transition: transform .2s ease-in-out;

  .ant-checkbox-wrapper {
    color: black !important;
    font-size: .6rem !important;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-color: ${colors.deepBlue} !important;

    &::after {
      left: 25%;
    }
  }
  .ant-checkbox-wrapper-checked .ant-checkbox-inner {
    background-color: ${colors.deepBlue};
  }
  .ant-checkbox::after {
    border: 1px solid ${colors.deepBlue};
  }
`;

export const Wrapper = styled.div`
  padding: 15px 30px;
  /* min-height: 200px; */

  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track {
    background: #efefef;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-thumb {
    background: #868686;
    border-radius: 50px;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #ddd;
  position: relative;
`;

export const Dash = styled.div`
  height: 3px;
  width: 37px;
  background-color: #dbdbdb;
  border-radius: 50px;
  margin: 0 0 5px;
`;

export const Title = styled.h3`
  color: #333;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const Description = styled.p`
  color: #4e4e4e;
  font-size: 12px;
  width: 90%;
  margin: 0;
`;

export const CloseButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  box-shadow: 0 5px 36px 0 rgba(12, 46, 59, 0.24);
  position: absolute;
  margin: 10px;
  top: 0;
  right: 0;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const Section = styled.div`
  margin-bottom: 8px;
`;

export const Label = styled.h3`
  color: #4e4e4e;
  font-size: 12px;
  font-weight: bolder;
  margin: 0;
`;

export const TrashButton = styled.button`
  width: 24px;
  height: 24px;
  margin: 0 5px;
  padding: 0;
  border-radius: 50px;
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: none;
  outline: none;
  cursor: pointer;
`;

export const TrashIcon = styled.img`
  width: 11px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 10px 0 20px;
`;

export const Button = styled.button<{ clean?: boolean, save?: boolean }>`
  border: none;
  outline: none;
  padding: 5px 20px;
  color: black;
  border-radius: 50px;
  text-transform: uppercase;
  font-size: .7rem;
  cursor: pointer;

  ${({ clean }) => clean && css`
    background-color: #DADADA;
    color: #707070;
  `}

  ${({ save }) => save && css`
    background-color: ${colors.deepBlue};
    color: white;
  `}
`;

export const Input = styled.input`
  text-align: center;
  background-color: ${colors.lightGray};
  border: none;
  width: 4rem;
  font-size: .7rem;
  margin: 0 5px 0 0;
  outline: none;
  border-bottom: 2px solid ${colors.secondaryGray};
`;

export const ColorsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const ColorContainer = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50px;
  outline: none;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  ${({ active }: { active?: boolean }) => (active && css`
    border: 1px solid black;
  `)}
`;

export const ColorBall = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${({ color }: { color: string }) => color || 'white'};
  border-radius: 50px;
  outline: none;
  padding: 0;
`;

export const PlusIcon = styled(PlusOutlined)`
  color: #B2B2B2;
`;

export const ModalContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
`;

export const Shadow = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const DropdownOpen = styled.button`
  height: 1rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-color: white;
  border: none;
  outline: none;

  @media only screen and (max-width: 450px) {
    position: absolute;
    bottom: 0;
  }
`;

export const DropdownClose = styled.button`
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-color: white;
  border: none;
  outline: none;
`;

export const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: ${({ progress }: { progress: number }) => {
    if (progress === -20 || progress === -40) return '0%';
    return (progress ? `${progress}%` : '0%');
  }};
  height: 3px;
  background-color: ${colors.blue};

  ${({ progress }) => {
    if (progress !== 100 && progress !== -20) {
      return css`transition: max-width 1s linear;`;
    }
    return '';
  }}
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CheckboxRow = styled.div`
  display: flex;
  width: 100%;
  margin: 7px 0;
`;

export const Icon = styled.img`
  width: 15px;
  margin-right: 10px;
`;

export const CheckboxParagraph = styled.p`
  margin: 0;
  color: #4e4e4e;
  width: 100%;
  font-size: 12px;
`;

export const Checkbox = styled(AntCheckbox)`
  margin-left: 10px;
`;

export const SliderCurrentVal = styled.p`
  width: 100%;
  color: #d5070a;
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  margin: 0;
  margin-bottom: -15px;
  pointer-events: none;
`;

export const Dark = styled.div`
  background-color: transparent;
  position: fixed;
  z-index: 4;
  width: 100%;
  height: 100vh;
`;
