import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Image } from 'components/atoms';

import crowdingSvg from 'assets/icons/analytics/crowding.svg';
// import crossingSvg from 'assets/icons/analytics/crossing.svg';
import maskSvg from 'assets/icons/analytics/mask.svg';
import timeSvg from 'assets/icons/analytics/time.svg';
import { useMobile, useVideoHeight, useWindowResize } from 'hooks';

import {
  Container,
  Wrapper,
  Card,
  CustomLink,
  SelectionRing,
  SelectionRingWrapper,
  LinkContainer,
  Dash,
  ForwardButton,
  BackwardsButton,
  NameContainer,
  Name,
} from './AnalyticNavbar.style';

const CustomImage = ({ src }: { src: string }) => (
  <Image width="20px" respWidth="20px" src={src} />
);

function AnalyticNavbar() {
  const location = useLocation();
  const history = useHistory();

  const linkMap = [
    {
      link: '/livedemo/mascara',
      icon: maskSvg,
      analyticName: 'Detecção de Máscaras',
    },
    {
      link: '/livedemo/aglomeracao',
      icon: crowdingSvg,
      analyticName: 'Detecção de aglomeração',
    },
    {
      link: '/livedemo/linha',
      icon: timeSvg,
      analyticName: 'Detecção de Cruzamento de Linha',
    },
    // { link: '/livedemo/permanencia', icon: crossingSvg,
    // analyticName: 'Detecção de Tempo de Permanência' },
  ];

  const index = linkMap.findIndex(({ link }) => link === location.pathname);

  const [carouselIndex, setCarouselIndex] = useState(index !== -1 ? index : 0);
  const [blankWidth, setBlankWidth] = useState(0);
  const [cardsMargin, setCardsMargin] = useState(0);
  const [name, setName] = useState(
    location.pathname !== '/livedemo/acesso'
      ? linkMap[carouselIndex]?.analyticName || ''
      : ''
  );
  const [clickInterval, setClickInterval] = useState({
    pressed: false,
    pressedX: 0,
  });
  const windowSizeReal = useWindowResize();
  const videoHeight = useVideoHeight();
  const isMobile = useMobile(700);
  const cardsWidth = isMobile ? 28 : 35;
  const windowSize = {
    width: (isMobile ? 400 : 486) * 0.7,
    height: windowSizeReal.height,
  };

  const goToIndex = (indexToGo: number) => {
    if (indexToGo >= 0 && indexToGo < linkMap.length) {
      setCarouselIndex(indexToGo);
      setName(linkMap[indexToGo].analyticName);
      history.push(linkMap[indexToGo].link);
    }
  };

  useEffect(() => {
    if (name === '') {
      setName(
        location.pathname !== '/livedemo/acesso'
          ? linkMap[carouselIndex]?.analyticName || ''
          : ''
      );
    }
  }, [location.pathname, name]);

  useEffect(() => {
    if (windowSize.width) {
      const numberOfMargins = 6;
      const margin = (windowSize.width - 7 * cardsWidth) / numberOfMargins;
      setCardsMargin(margin);
    }
  }, [windowSize.width]);

  useEffect(() => {
    if (cardsMargin) {
      setBlankWidth((cardsMargin + cardsWidth) * carouselIndex + cardsWidth);
    }
  }, [cardsMargin, carouselIndex]);

  const handleDragStop = (event: DraggableEvent, data: DraggableData) => {
    const movedX = data.x;
    if (
      Math.abs(Math.abs(clickInterval.pressedX) - Math.abs(movedX)) <
      cardsMargin + cardsWidth
    ) {
      // Clicked
      // @ts-ignore
      const elArray: Element[] = [...event.path];

      elArray.forEach((element) => {
        if (element.tagName === 'A') {
          const siblings = element.parentElement?.childNodes;

          if (siblings) {
            siblings.forEach((sibling, i) => {
              if (sibling === element) {
                goToIndex(i);
              }
            });
          }
        }
      });
      return;
    }
    let newIndex = -Math.round(movedX / (cardsMargin + cardsWidth));
    if (newIndex < 0) newIndex = 0;
    if (newIndex > linkMap.length - 1) newIndex = linkMap.length - 1;
    if (newIndex !== carouselIndex) {
      goToIndex(newIndex);
    }
  };

  const handleStart = (event: DraggableEvent, data: DraggableData) => {
    setClickInterval({
      pressed: true,
      pressedX: data.x,
    });
  };

  let marginBottom = (window.innerHeight - 80 - videoHeight) / 2 - 55;

  if (window.innerHeight < 700) {
    marginBottom = 20;
  }

  return (
    <Wrapper $marginBottom={marginBottom}>
      <BackwardsButton onClick={() => goToIndex(carouselIndex - 1)}>
        <LeftOutlined style={{ color: '#504a4a' }} />
      </BackwardsButton>
      <ForwardButton onClick={() => goToIndex(carouselIndex + 1)}>
        <RightOutlined style={{ color: '#504a4a' }} />
      </ForwardButton>
      <Dash />
      {/* {!!name && (
        <NameContainer>
          <Name>{name}</Name>
        </NameContainer>
      )} */}

      <NameContainer>
        <Name>{name}</Name>
      </NameContainer>
      <Container>
        {/* {location.pathname !== '/livedemo/acesso' ? (
          <SelectionRingWrapper>
            <SelectionRing />
          </SelectionRingWrapper>
        ) : (
          <SelectionRingWrapper>
            <SelectionRing />
          </SelectionRingWrapper>
        )} */}

        {location.pathname !== '/livedemo/acesso' && (
          <SelectionRingWrapper>
            <SelectionRing />
          </SelectionRingWrapper>
        )}

        {/* <SelectionRingWrapper>
          <SelectionRing />
        </SelectionRingWrapper> */}
        <Draggable
          axis="x"
          defaultPosition={{ x: 0, y: 0 }}
          disabled={!isMobile}
          position={{ x: -blankWidth, y: 0 }}
          onStart={handleStart}
          onStop={handleDragStop}
        >
          <div>
            <LinkContainer margin={cardsMargin}>
              {linkMap.map(({ link, icon, analyticName }, i) => (
                <CustomLink
                  width={cardsWidth}
                  margin={cardsMargin}
                  onClick={() => {
                    setCarouselIndex(i);
                    setName(analyticName);
                  }}
                  to={link}
                >
                  <Card active={i === carouselIndex}>
                    <CustomImage src={icon} />
                  </Card>
                </CustomLink>
              ))}
            </LinkContainer>
          </div>
        </Draggable>
      </Container>
    </Wrapper>
  );
}

AnalyticNavbar.defaultProps = {
  mobile: false,
};

export default AnalyticNavbar;
