import React, {
  useState,
  createContext,
  useContext,
} from 'react';

import { Crowding } from 'components/organisms/Analytics';
import { OptionsConfig } from 'components/organisms/Options/Options';
import { SecondaryBackground } from 'components/atoms';
import { Options } from 'components/organisms';
import OptionsMobile from 'components/organisms/Options/Mobile';
import LastNotificationsModalMobile from 'components/molecules/LastNotifications/Mobile';
import { useMobile } from 'hooks';
import crowdingSvg from 'assets/icons/analytics/crowding.svg';
import personSvg from 'assets/icons/person.svg';
import carSvg from 'assets/icons/car.svg';
import busSvg from 'assets/icons/bus.svg';
import truckSvg from 'assets/icons/truck.svg';

type NotificationContextType = {
  notifications: (string | null)[],
  addNotification: (url: string, amount: number) => void,
  showNotifications: () => void,
}

const NotificationContext = createContext<NotificationContextType>({
  notifications: [null, null, null, null, null],
  addNotification: () => { },
  showNotifications: () => { },
});

function CrowdingAnalytic() {
  const [options, setOptions] = useState<OptionsConfig>();
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const isMobile = useMobile(600);
  const [times, setTimes] = useState<(number | null)[]>([
    null, null, null, null, null,
  ]);
  const [notifications, setNotifications] = useState<(string | null)[]>([
    null, null, null, null, null,
  ]);
  const [amounts, setAmounts] = useState<(number | null)[]>([
    null, null, null, null, null,
  ]);

  const handleAddNotification = (url: string, amount: number) => {
    const notificationsCopy = [...notifications];
    notificationsCopy.pop();
    setNotifications([url, ...notificationsCopy]);

    const timesCopy = [...times];
    timesCopy.pop();
    setTimes([Date.now(), ...timesCopy]);

    const amountsCopy = [...amounts];
    amountsCopy.pop();
    setAmounts([amount, ...amountsCopy]);
  };

  const deleteEverything = () => {
    setTimes([null, null, null, null, null]);
    setAmounts([null, null, null, null, null]);
    setNotifications([null, null, null, null, null]);
  };

  const detectionTextMap = {
    person: { singular: 'pessoa', plural: 'pessoas' },
    car: { singular: 'carro', plural: 'carros' },
    truck: { singular: 'caminhão', plural: 'caminhões' },
    bus: { singular: 'ônibus', plural: 'ônibus' },
  };

  const passedOptions = options?.notify.filter((key) => Object.keys(detectionTextMap).includes(key)
  );

  const validKeys = passedOptions as any as ('person' | 'car' | 'truck' | 'bus')[];

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification: handleAddNotification,
        showNotifications: () => setShowNotifications(true),
      }}
    >
      <SecondaryBackground mobileHigher>
        {isMobile ? (
          <>
            <Crowding
              options={options}
            />
            <OptionsMobile
              notify={[
                { value: 'person', label: 'Pessoas', icon: personSvg },
                { value: 'car', label: 'Carros', icon: carSvg },
                { value: 'truck', label: 'Caminhões', icon: truckSvg },
                { value: 'bus', label: 'Ônibus', icon: busSvg },
              ]}
              showQuantity
              showColorPicker
              onChange={setOptions}
              mobileHeight="29.6rem"
              analyticInfo={{
                name: 'Detecção de Aglomeração',
                description: 'Você está usando a tecnologia de detecção de aglomeração da Avantia',
                icon: crowdingSvg,
              }}
            />
          </>
        ) : (
          <>
            <Crowding
              options={options}
            />
            <Options
              notify={[
                { value: 'person', label: 'Pessoas', icon: personSvg },
                { value: 'car', label: 'Carros', icon: carSvg },
                { value: 'truck', label: 'Caminhões', icon: truckSvg },
                { value: 'bus', label: 'Ônibus', icon: busSvg },
              ]}
              showQuantity
              showColorPicker
              onChange={setOptions}
              analyticInfo={{
                name: 'Detecção de Aglomeração',
                description: 'Você está usando a tecnologia de detecção de aglomeração da Avantia',
                icon: crowdingSvg,
              }}
            />
          </>
        )}
        <LastNotificationsModalMobile
          visible={showNotifications}
          setVisible={setShowNotifications}
          urlList={notifications}
          times={times}
          amounts={amounts}
          text="Tecnologia de Aglomeração"
          infoType="amount"
          deleteEverything={deleteEverything}
          detectionText={(validKeys || []).length > 1 ? ({
            singular: 'elemento',
            plural: 'elementos',
          }) : validKeys && detectionTextMap[validKeys[0]]}
        />
      </SecondaryBackground>
    </NotificationContext.Provider>
  );
}

export const useNotification = () => useContext(NotificationContext);

export default CrowdingAnalytic;
