import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { notification } from 'antd';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

import { useOptionsDropdown } from 'contexts/OptionsContext';
import { Slider } from 'components/atoms';

import {
  Card,
  Wrapper,
  Section,
  TitleContainer,
  Dash,
  Title,
  Label,
  ButtonContainer,
  Button,
  ProgressBar,
  CheckboxContainer,
  CheckboxRow,
  Icon,
  Checkbox,
  CheckboxParagraph,
  SliderCurrentVal,
  Dark,
} from './OptionsMobile.style';
import { OptionsProps } from '../Options';
import AnalyticInfo from './AnalyticInfo';

const defaultConfig: OptionsConfig = {
  notify: ['mask', 'nomask', 'person'],
  quantity: 1,
  color: '#4BBFD1',
  time: 5,
  default: true,
  counter: 0,
};

function Options({
  notify,
  onChange,
  showQuantity = false,
  showTime = false,
  mobileHeight = '10rem',
  analyticInfo,
}: OptionsProps) {
  const changeObjRef = useRef(defaultConfig);
  const [tempObj, setTempObj] = useState(defaultConfig);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [progress, setProgress] = useState(-2);
  const [firstChange, setFirstChange] = useState(true);
  const instantRef = useRef(false);
  const showMessageRef = useRef(true);
  const lastDirection = useRef<('up' | 'down' | null)>(null);
  const [, setOpen] = useOptionsDropdown();

  const handleChange = useCallback((obj: OptionsConfig, showMessageOverride: boolean = false) => {
    onChange({
      ...obj,
      counter: obj.default ? obj.counter : (obj.counter || 0) + 1,
    });
    if (!firstChange && (showMessageRef.current || showMessageOverride)) {
      notification.success({
        message: 'Mudanças feitas com sucesso!',
      });
    }
  }, [firstChange]);

  const generateHandler = (
    key: string,
    both: boolean = false,
    instant: boolean = false,
  ) => (value: any) => {
    setFirstChange(false);
    instantRef.current = instant;
    setTempObj((prevObj) => ({
      ...prevObj,
      default: false,
      [key]: value,
    }));
    if (both) {
      changeObjRef.current = {
        ...changeObjRef.current,
        default: false,
        [key]: value,
      };
    }
  };

  useEffect(() => {
    setOpen(() => () => {
      setDropdownOpen(true);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      showMessageRef.current = false;
      handleChange({ ...defaultConfig, default: false });
    }, 200);
  }, []);

  useEffect(() => {
    if (progress === 5) {
      setTimeout(() => {
        setProgress(4);
      }, 0);
      return () => { };
    }
    if (progress > 0) {
      const timeout = setTimeout(() => {
        setProgress(progress - 1);
      }, 1000);

      return () => clearTimeout(timeout);
    }

    return () => { };
  }, [progress]);

  useEffect(() => {
    if (instantRef.current) {
      showMessageRef.current = false;
      handleChange({ ...tempObj });
      return () => { };
    }

    if (firstChange) return () => { };

    showMessageRef.current = true;

    setProgress(5);

    const timeoutId = setTimeout(() => {
      changeObjRef.current = { ...tempObj };
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [tempObj, firstChange]);

  useEffect(() => {
    if (progress === 0) {
      showMessageRef.current = true;
      setTimeout(() => {
        handleChange(changeObjRef.current, true);
      }, 1000);
    }
    if (progress === -1 && !firstChange) {
      handleChange(changeObjRef.current);
    }
    if (firstChange) {
      handleChange(changeObjRef.current);
    }
  }, [handleChange, progress, firstChange]);

  const handleFinish = () => {
    showMessageRef.current = true;
    setProgress(-1);
    changeObjRef.current = { ...tempObj };
  };

  const handleDrag = (event: DraggableEvent, data: DraggableData) => {
    lastDirection.current = data.deltaY > 0 ? 'down' : 'up';
  };

  const handleStop = () => {
    if (lastDirection.current === 'down') {
      setDropdownOpen(false);
    }
  };

  return (
    <>
      {dropdownOpen && <Dark onClick={() => setDropdownOpen(false)} />}
      <Draggable
        bounds={{
          top: 0,
          bottom: parseInt(mobileHeight, 10) * 16 + 10,
        }}
        position={{ x: 0, y: dropdownOpen ? 0 : parseInt(mobileHeight, 10) * 16 + 10 }}
        onDrag={handleDrag}
        onStop={handleStop}
        handle=".handle"
        axis="y"
      >
        <Card mobileHeight={mobileHeight} mobileShow>
          <ProgressBar progress={progress * 20} />
          <TitleContainer className="handle" onClick={() => setDropdownOpen(false)}>
            <Dash />
            <Title>Alertas</Title>
          </TitleContainer>
          <Wrapper>
            {notify && (
              <Section>
                <Label>Notificar</Label>
                <CheckboxContainer>
                  {notify.map(({ icon, label, value }) => (
                    <CheckboxRow key={label}>
                      <Icon src={icon} />
                      <CheckboxParagraph>{label}</CheckboxParagraph>
                      <Checkbox
                        defaultChecked={defaultConfig.notify.includes(value)}
                        checked={tempObj.notify.includes(value)}
                        onChange={(event) => {
                          let newValues = [...tempObj.notify];

                          if (event.target.checked) {
                            newValues.push(value);
                          } else {
                            newValues = newValues.filter((val) => val !== value);
                          }

                          generateHandler('notify')(newValues);
                        }}
                      />
                    </CheckboxRow>
                  ))}
                </CheckboxContainer>
              </Section>
            )}
            {showQuantity && (
              <Section>
                <Label>Número de pessoas</Label>
                <Slider
                  value={tempObj.quantity || 0}
                  min={0}
                  max={5}
                  onChange={(value: number) => { generateHandler('quantity')(value); }}
                  marks={{
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5+',
                  }}
                />
              </Section>
            )}
            {showTime && (
              <Section>
                <Label>Notificar a partir de</Label>
                <SliderCurrentVal>
                  {tempObj.time || 0}
                  {' '}
                  seg
                </SliderCurrentVal>
                <Slider
                  value={tempObj.time || 0}
                  min={0}
                  max={20}
                  onChange={(value: number) => generateHandler('time')(value)}
                  marks={{ 0: '0 seg', 20: '20 seg' }}
                />
              </Section>
            )}
            <ButtonContainer>
              <Button
                onClick={() => {
                  setProgress(-1);
                  instantRef.current = true;
                  setTempObj({ ...defaultConfig });
                  notification.success({
                    message: 'Mudanças feitas com sucesso!',
                  });
                }}
                clean
              >
                Aplicar Padrão
              </Button>
              <Button save onClick={handleFinish}>Executar</Button>
            </ButtonContainer>
          </Wrapper>
          <AnalyticInfo analyticInfo={analyticInfo} />
        </Card>
      </Draggable>
    </>
  );
}

export type OptionsConfig = {
  notify: ('mask' | 'nomask' | 'person' | 'car' | 'truck' | 'bus')[],
  quantity?: number,
  time?: number,
  color?: string,
  default?: boolean | null,
  counter?: number,
}

export default Options;
