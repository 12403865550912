import React, {
  useState,
  useEffect,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

import { Image } from 'components/atoms';

import crowdingSvg from 'assets/icons/analytics/crowding.svg';
// import crossingSvg from 'assets/icons/analytics/crossing.svg';
import maskSvg from 'assets/icons/analytics/mask.svg';
import timeSvg from 'assets/icons/analytics/time.svg';
import upSvg from 'assets/icons/up.svg';
import { useWindowResize } from 'hooks';
import { useOptionsDropdown } from 'contexts/OptionsContext';

import {
  Container,
  Card,
  CustomLink,
  SelectionRing,
  SelectionRingWrapper,
  LinkContainer,
  Dash,
  NameContainer,
  Name,
} from './AnalyticNavbarMobile.style';

const CustomImage = ({ src }: { src: string }) => (
  <Image width="20px" respWidth="20px" src={src} />
);

function AnalyticNavbarMobile() {
  const location = useLocation();
  const history = useHistory();

  const linkMap = [
    { link: '/livedemo/mascara', icon: maskSvg, analyticName: 'Detecção de Máscaras' },
    { link: '/livedemo/aglomeracao', icon: crowdingSvg, analyticName: 'Detecção de aglomeração' },
    { link: '/livedemo/linha', icon: timeSvg, analyticName: 'Detecção de Cruzamento de Linha' },
    // { link: '/livedemo/permanencia', icon: crossingSvg,
    // analyticName: 'Detecção de Tempo de Permanência' },
  ];

  const index = linkMap.findIndex(({ link }) => link === location.pathname);

  const [carouselIndex, setCarouselIndex] = useState(
    index !== -1 ? index : 0,
  );
  const [blankWidth, setBlankWidth] = useState(0);
  const [cardsMargin, setCardsMargin] = useState(0);
  const [name, setName] = useState(
    location.pathname !== '/livedemo/acesso'
      ? (linkMap[carouselIndex]?.analyticName || '') : '',
  );
  const cardsWidth = 35;
  const [clickInterval, setClickInterval] = useState({
    pressed: false,
    pressedX: 0,
  });
  const windowSize = useWindowResize();
  const [open] = useOptionsDropdown();

  useEffect(() => {
    if (name === '') {
      setName(
        location.pathname !== '/livedemo/acesso'
          ? (linkMap[carouselIndex]?.analyticName || '') : '',
      );
    }
  }, [location.pathname, name]);

  useEffect(() => {
    if (windowSize.width && windowSize.width < 700) {
      const numberOfMargins = 6;
      const margin = (windowSize.width - 7 * cardsWidth) / numberOfMargins;
      setCardsMargin(margin);
    }
  }, [windowSize.width]);

  useEffect(() => {
    if (cardsMargin) {
      setBlankWidth((cardsMargin + cardsWidth) * carouselIndex + cardsWidth);
    }
  }, [cardsMargin, carouselIndex]);

  const handleDragStop = (event: DraggableEvent, data: DraggableData) => {
    const movedX = data.x;
    if (Math.abs(Math.abs(clickInterval.pressedX) - Math.abs(movedX)) < cardsMargin + cardsWidth) {
      // Clicked
      // @ts-ignore
      const elArray: Element[] = [...event.path];

      elArray.forEach((element) => {
        if (element.tagName === 'A') {
          const siblings = element.parentElement?.childNodes;

          if (siblings) {
            siblings.forEach((sibling, i) => {
              if (sibling === element) {
                setCarouselIndex(i);
                setName(linkMap[i].analyticName);
                history.push(linkMap[i].link);
              }
            });
          }
        }
      });
      return;
    }
    let newIndex = -Math.round(movedX / (cardsMargin + cardsWidth));
    if (newIndex < 0) newIndex = 0;
    if (newIndex > linkMap.length - 1) newIndex = linkMap.length - 1;
    if (newIndex !== carouselIndex) {
      setCarouselIndex(newIndex);
      setName(linkMap[newIndex].analyticName);
      history.push(linkMap[newIndex].link);
    }
  };

  const handleStart = (event: DraggableEvent, data: DraggableData) => {
    setClickInterval({
      pressed: true,
      pressedX: data.x,
    });
  };

  return (
    <Container>
      <Dash />
      <NameContainer onClick={open}>
        <Name>{name}</Name>
        {name && <Image width="15px" respWidth="15px" src={upSvg} />}
      </NameContainer>
      {location.pathname !== '/livedemo/acesso' && (
        <SelectionRingWrapper>
          <SelectionRing />
        </SelectionRingWrapper>
      )}
      <Draggable
        axis="x"
        defaultPosition={{ x: 0, y: 0 }}
        position={{ x: -blankWidth, y: 0 }}
        onStart={handleStart}
        onStop={handleDragStop}
      >
        <div>
          <LinkContainer
            margin={cardsMargin}
          >
            {linkMap.map(({ link, icon, analyticName }, i) => (
              <CustomLink
                width={cardsWidth}
                margin={cardsMargin}
                onClick={() => {
                  setCarouselIndex(i);
                  setName(analyticName);
                }}
                to={link}
              >
                <Card active={i === carouselIndex}>
                  <CustomImage src={icon} />
                </Card>
              </CustomLink>
            ))}
          </LinkContainer>
        </div>
      </Draggable>
    </Container>
  );
}

export default AnalyticNavbarMobile;
