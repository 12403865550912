import styled from 'styled-components';

import { flexcc } from 'styles/variables';

export const Container = styled.div`
  ${flexcc}
  position: relative;
  width: ${({ width }: { width?: number, height?: number | string }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '80%')};
  overflow: hidden;
  margin: 0 auto;
  border-radius: 5px;
  max-width: 1280px;
  width: 100%;
  height: 720px;

  @media only screen and (max-width: 600px) {
    height: 600px;
    max-width: 480px;
  }

  @media only screen and (min-width: 600px) {
    @media only screen and (max-height: 900px) {
      height: 500px;
    }
  }
`;
