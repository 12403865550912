import styled from 'styled-components';

export const Container = styled.a<{
  $marginRight: number;
  $marginBottom: number;
}>`
  width: 9rem;
  height: 2.64rem;

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  box-shadow: 0 3px 16px 0 rgba(12, 46, 59, 0.1);
  background-color: #ffffff;
  border-radius: 1.21rem;
  z-index: 10;

  cursor: pointer;
  border: none;

  position: fixed;
  top: 87.2%;
  left: 0;

  @media only screen and (min-width: 1660px) {
    top: 83.2%;
  }

  margin: ${({ $marginBottom, $marginRight }) =>
    `${$marginBottom}px ${$marginRight}px`};
`;

export const TextContainer = styled.p`
  font-size: 0.9rem;
  font-weight: 900;
  line-height: 1.5;
  text-align: center;
  color: #4e4e4e;

  margin: 0;
`;
