import styled from 'styled-components';

export const InfoContainer = styled.div`
  border-top: 1px solid #ddd;
  height: 8rem;
  width: 100%;
  padding: 30px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;

  @media only screen and (min-width: 600px) {
    border: none;
    padding: 10px;
  }
`;

export const ImageContainer = styled.div`
  width: 48px;
  height: 48px;
  padding: 10px;
  border-radius: 50px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const Image = styled.img`
  width: 100%;
  filter: invert(60%) sepia(14%) saturate(4855%) hue-rotate(194deg) brightness(76%) contrast(85%);
`;

export const TextContainer = styled.div`
  width: 60%;
`;

export const Name = styled.p`
  color: #4e4e4e;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  margin: 0;
`;

export const Desc = styled.p`
  color: #717171;
  font-size: 12px;
  margin: 0;
`;
