import React from 'react';

import { Container } from './TimeCounter.style';

function TimeCounter({ time = '00:00' }: { time: string }) {
  return (
    <Container>{time}</Container>
  );
}

export default TimeCounter;
