import React from 'react';

import { Image } from 'components/atoms';
import { useVideoHeight } from 'hooks';

import { Container, TextContainer } from './Feedback.styled';

import feedbackIcon from '../../../assets/icons/feedback.svg';

function Feedback() {
  const videoHeight = useVideoHeight();

  let marginBottom = (window.innerHeight - 80 - videoHeight) / 2 - 35 / 2;

  const marginLeft =
    window.innerWidth > 1280 ? (window.innerWidth - 1280) / 2 + 50 : 50;

  if (window.innerHeight < 700) {
    marginBottom = 20;
  }

  return (
    <Container
      $marginBottom={marginBottom}
      $marginRight={marginLeft}
      href="https://docs.google.com/forms/d/1CJDrjr75IrdgIO1BzhFouoeG-DCPwIykcIj6i769mCM/viewform?edit_requested=true"
    >
      <Image width="1.14rem" respWidth="1.14rem" src={feedbackIcon} />
      <TextContainer>Feedback</TextContainer>
    </Container>
  );
}

export default Feedback;
