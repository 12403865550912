import React from 'react';

import { Image } from 'components/atoms';

import logoPng from 'assets/logo.png';
import feedbackIcon from 'assets/icons/feedback.svg';

import {
  Base,
  LogoContainer,
  NavItem,
  BetaContainer,
  BetaText,
  LiveDemoText,
  Separator,
  FeedbackContainer,
} from './HeaderMobile.style';

function HeaderMobile() {
  return (
    <Base>
      <NavItem>
        <LogoContainer>
          <Image width="10rem" respWidth="6rem" src={logoPng} />
        </LogoContainer>
      </NavItem>
      <NavItem>
        <BetaContainer>
          <LiveDemoText>Live Demo</LiveDemoText>
          <BetaText>Beta</BetaText>
        </BetaContainer>
      </NavItem>
      <NavItem>
        <Separator />
        <FeedbackContainer href="https://docs.google.com/forms/d/1CJDrjr75IrdgIO1BzhFouoeG-DCPwIykcIj6i769mCM/viewform?edit_requested=true">
          <Image width="1.4rem" respWidth="1.4rem" src={feedbackIcon} />
          <span>Feedbacks</span>
        </FeedbackContainer>
      </NavItem>
    </Base>
  );
}

export default HeaderMobile;
