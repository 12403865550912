import React from 'react';
import {
  BrowserRouter as Browser,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { Header } from 'components/organisms';
import { AnalyticNavbar } from 'components/molecules';
import AnalyticNavbarMobile from 'components/molecules/AnalyticNavbar/Mobile';
import GlobalProvider from 'contexts/GlobalProvider';
import {
  Analytic,
  Crowding,
  Access,
  ExpiredSession,
  Display,
  Share,
  Choose,
  Time,
  Line,
} from 'components/pages';
import { useMobile } from 'hooks';
import { OptionsButton } from 'components/atoms';

import Feedback from 'components/organisms/Feedback';
import { AppWrapper } from './App.style';

function App() {
  const isMobile = useMobile(600);

  return (
    <Browser>
      <GlobalProvider>
        <AppWrapper>
          <Header mobile={isMobile || false} />
          <Switch>
            <Route exact path="/(|livedemo)">
              <Redirect to="/livedemo/acesso" />
            </Route>
            <Route path="/livedemo/acesso" component={Access} />
            <Route path="/livedemo/escolha" component={Choose} />
            <Route path="/livedemo/mascara" component={Analytic} />
            <Route path="/livedemo/aglomeracao" component={Crowding} />
            <Route path="/livedemo/linha" component={Line} />
            <Route path="/livedemo/permanencia" component={Time} />
            <Route path="/livedemo/expired" component={ExpiredSession} />
            <Route path="/livedemo/display" component={Display} />
            <Route path="/livedemo/s/:mediaId" component={Share} />
          </Switch>

          <Route path="/livedemo/(escolha|mascara|aglomeracao|linha|permanencia)">
            {!isMobile && <Feedback />}
          </Route>

          {!isMobile && (
            <Route path="/livedemo/(escolha|mascara|aglomeracao|linha|permanencia)">
              <AnalyticNavbar />
            </Route>
          )}

          {isMobile && (
            <Route path="/livedemo/(escolha|mascara|aglomeracao|linha|permanencia)">
              <AnalyticNavbarMobile />
            </Route>
          )}

          {/* <Route path="/livedemo/(acesso|escolha|mascara|aglomeracao|linha|permanencia)">
            {isMobile ? (
              <AnalyticNavbarMobile />
            ) : (
              <>
                <AnalyticNavbar />
              </>
            )}
          </Route> */}

          <Route path="/livedemo/(mascara|aglomeracao|linha|permanencia)">
            <OptionsButton />
          </Route>
        </AppWrapper>
      </GlobalProvider>
    </Browser>
  );
}

export default App;
