import styled, { css } from 'styled-components';

export const StyledCanvas = styled.canvas<{ $lookToWidth: boolean }>`
  ${({ $lookToWidth }) => ($lookToWidth ? css`width: 100%;` : css`height: 100%;`)}
  object-fit: cover;
`;

export const CanvasContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
`;
