import React from 'react';

import { useOptionsDropdown } from 'contexts/OptionsContext';
import cogIcon from 'assets/icons/cog.svg';
import { useVideoHeight } from 'hooks';

import { Button, Icon } from './OptionsButton.style';

function OptionsButton() {
  const [open] = useOptionsDropdown();
  const videoHeight = useVideoHeight();

  let marginBottom = (((window.innerHeight - 80) - videoHeight) / 2) - (35 / 2);
  const marginRight = window.innerWidth > 1280 ? ((window.innerWidth - 1280) / 2) + 50 : 50;

  if (window.innerHeight < 700) {
    marginBottom = 20;
  }

  return (
    <Button onClick={open} $marginBottom={marginBottom} $marginRight={marginRight}>
      <Icon src={cogIcon} />
    </Button>
  );
}

export default OptionsButton;
