import styled, { css } from 'styled-components';
import { Modal as AntModal } from 'antd';

import { flexcc } from 'styles/variables';

export const Modal = styled(AntModal)`
  height: 100vh;

  .ant-modal-content {
    height: 100%;

    .ant-modal-body {
      height: calc(100% - 55px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: relative;
    }
  }

  @media only screen and (min-width: 600px) {
    height: 550px;
    width: 513px;
  }
`;

export const Description = styled.p`
  font-size: 12px;
  color: #717171;
  position: absolute;
  top: -8px;
  left: 24px;
  margin: 0;
  width: 70%;
`;

export const Base = styled.div`
  border: 1px solid #cccccc;
  background-color: white;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 5px 0;
  color: black;
  cursor: ${({ clickable }: { clickable: boolean }) => (clickable ? 'pointer' : 'unset')};
`;

export const Title = styled.p`
  font-weight: bold;
  color: #4e4e4e;
  margin: 0;
  font-size: 16px;
  display: inline-block;
`;

export const TitleIcon = styled.img`
  width: 15px;
  margin-right: 8px;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalImageContainer = styled(ImageContainer)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  padding: 11px;
  border-top: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: 600px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const ModalImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width: 600px) {
    width: 70%;
  }
`;

export const Placeholder = styled.div`
  background-color: #C1C1C1;
  width: 15%;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

export const ModalPlaceholder = styled(Placeholder)`
  background-color: white;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 60px);
  width: 100%;
`;

export const ImageFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
`;

export const Timestamp = styled.p`
  margin: 0;
  font-size: 10px;
  color: #4e4e4e;
  white-space: pre-line;
  width: 35px;
  text-align: center;
`;

export const ShareButton = styled.button`
  background-color: white;
  border-radius: 50px;
  border: none;
  outline: none;
  width: 35px;
  height: 35px;
  backdrop-filter: blur(50px);
  box-shadow: 0 5px 36px 0 rgba(12, 46, 59, 0.24);
  background-color: #ffffff;
  cursor: pointer;
  transition: box-shadow ease-in-out .5s;
  ${flexcc}

  &:hover {
    box-shadow: 0 5px 36px 0 rgba(12, 46, 59, 0.5);
  }
`;

export const ShareIcon = styled.img`
  width: 10px;
`;

export const Image = styled.img`
  width: 15%;
  object-fit: cover;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

export const ModalImage = styled(Image)`
  cursor: pointer;

  ${({ active }: { active: boolean }) => active && css`
    border: 1px solid #747474;
    height: 80%;
  `}
`;

export const ModalBigImage = styled.img`
  width: 100%;
  object-fit: contain;
  max-height: 70%;
`;

export const MoveButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  box-shadow: 0 5px 36px 0 rgba(12, 46, 59, 0.24);
  position: absolute;
  margin: 0 13px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const ForwardButton = styled(MoveButton)`
  right: 0;
`;

export const BackwardsButton = styled(MoveButton)`
  left: 0;
`;
