import React from 'react';

const itemsContent = [
  <>
    Durante a sessão as <strong>fotos</strong> e <strong> vídeos </strong>
    ficarão disponíveis.
  </>,

  <>
    <strong>Compartilhe</strong> as fotos e vídeos das detecções com seus amigos
    e amigas nas redes sociais.
  </>,

  <>
    Estamos em fase de testes, deixe seu <strong>feedback</strong> e nos ajude a
    continuar melhorando.
  </>,
];

export default itemsContent;
