import React, {
  useState,
  createContext,
  useContext,
} from 'react';

import { SecondaryBackground } from 'components/atoms';
import { Mask } from 'components/organisms/Analytics';
import { Options } from 'components/organisms';
import OptionsMobile from 'components/organisms/Options/Mobile';
import { OptionsConfig } from 'components/organisms/Options/Options';
import { useMobile } from 'hooks';
import closeSvg from 'assets/icons/close.svg';
import checkSvg from 'assets/icons/check.svg';
import maskSvg from 'assets/icons/analytics/mask.svg';
import LastNotificationsModalMobile from 'components/molecules/LastNotifications/Mobile';

type NotificationContextType = {
  notifications: (string | null)[],
  addNotification: (url: string, amount: number) => void,
  showNotifications: () => void,
}

const NotificationContext = createContext<NotificationContextType>({
  notifications: [null, null, null, null, null],
  addNotification: () => { },
  showNotifications: () => { },
});

function Analytic() {
  const [options, setOptions] = useState<OptionsConfig>();
  const [showNotifications, setShowNotifications] = useState<boolean>(false);
  const isMobile = useMobile(600);
  const [times, setTimes] = useState<(number | null)[]>([
    null, null, null, null, null,
  ]);
  const [amounts, setAmounts] = useState<(number | null)[]>([
    null, null, null, null, null,
  ]);
  const [notifications, setNotifications] = useState<(string | null)[]>([
    null, null, null, null, null,
  ]);

  const deleteEverything = () => {
    setTimes([null, null, null, null, null]);
    setAmounts([null, null, null, null, null]);
    setNotifications([null, null, null, null, null]);
  };

  const handleAddNotification = (url: string, amount: number) => {
    const notificationsCopy = [...notifications];
    notificationsCopy.pop();
    setNotifications([url, ...notificationsCopy]);

    const timesCopy = [...times];
    timesCopy.pop();
    setTimes([Date.now(), ...timesCopy]);

    const amountsCopy = [...amounts];
    amountsCopy.pop();
    setAmounts([amount, ...amountsCopy]);
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification: handleAddNotification,
        showNotifications: () => setShowNotifications(true),
      }}
    >
      <SecondaryBackground mobileHigher>
        {isMobile ? (
          <>
            <Mask
              options={options}
            />
            <OptionsMobile
              notify={[
                { value: 'mask', label: 'Pessoas com Máscara', icon: checkSvg },
                { value: 'nomask', label: 'Pessoas sem Máscara', icon: closeSvg },
              ]}
              onChange={setOptions}
              mobileHeight="20.5rem"
              analyticInfo={{
                name: 'Detecção de Máscara',
                description: 'Você está usando a tecnologia de detecção de máscaras da Avantia.',
                icon: maskSvg,
              }}
            />
          </>
        ) : (
          <>
            <Mask
              options={options}
            />
            <Options
              notify={[
                { value: 'mask', label: 'Pessoas com Máscara', icon: checkSvg },
                { value: 'nomask', label: 'Pessoas sem Máscara', icon: closeSvg },
              ]}
              onChange={setOptions}
              analyticInfo={{
                name: 'Detecção de Máscara',
                description: 'Você está usando a tecnologia de detecção de máscaras da Avantia.',
                icon: maskSvg,
              }}
            />
          </>
        )}
        <LastNotificationsModalMobile
          visible={showNotifications}
          setVisible={setShowNotifications}
          urlList={notifications}
          times={times}
          amounts={amounts}
          text="Tecnologia de Reconhecimento de Máscara"
          deleteEverything={deleteEverything}
          detectionText={{
            singular: 'pessoa',
            plural: 'pessoas',
          }}
        />
      </SecondaryBackground>
    </NotificationContext.Provider>
  );
}

export const useNotification = () => useContext(NotificationContext);

export default Analytic;
