import styled from 'styled-components';

import colors from 'styles/colors';
import { flexcc, flex } from 'styles/variables';

export const Base = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  position: relative;

  width: 100%;
  height: 5rem;

  padding: 0 5.07rem 0;

  &::after {
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    content: '';
    z-index: 3;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  @media only screen and (max-width: 750px) {
    padding: 0.5rem 0;
  }
`;

export const NavItem = styled.div`
  ${flexcc}
`;

export const WhatsappIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-left: 10px;

  @media only screen and (max-width: 600px) {
    margin: 0;
  }
`;

export const Text = styled.p`
  margin: 0;
  display: inline;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`;

export const LogoContainer = styled.div`
  ${flex('flex-start', 'center', 'unset', 'unset')}

  margin-left: -5rem;

  img {
    height: 3rem;
    object-fit: cover;
  }
`;

export const BetaContainer = styled.div`
  ${flex('space-between', 'center', 'unset', 'unset')}

  width: 12.84rem;
  height: 2.85rem;

  border-radius: 5px;
  background-color: rgba(85, 121, 183, 0.12);
`;

export const LiveDemoText = styled.p`
  margin: 0 auto;

  font-size: 17px;
  font-weight: 800;
  line-height: 1.35;
  text-align: center;
  color: ${colors.blue};
`;

export const BetaText = styled.p`
  ${flex('center', 'center', 'unset', 'unset')}

  padding: 1px 5px 3px;
  border-radius: 3px;
  border: solid 1px #5579b7;

  margin: 0 auto;

  font-size: 11px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #5579b7;
`;

export const Separator = styled.div`
  width: 0;
  height: 52px;
  border: solid 1px #dbdbdb;

  margin: 0 1.25rem;
`;

export const FeedbackContainer = styled.a`
  ${flex('flex-end', 'center', 'unset', 'unset')}
  flex-direction: column;

  border: none;
  background: transparent;
  text-decoration: none;
  /* cursor: pointer; */

  span {
    font-size: 0.79rem;
    font-weight: bold;
    font-family: 'San Francisco', Helvetica, Arial, san-serif;
    line-height: 1.64;
    text-align: center;
    color: #4e4e4e;
  }
`;
