import React, { useState } from 'react';
import { notification } from 'antd';
import { Redirect } from 'react-router-dom';

import { Title } from 'components/atoms/Text';
import arrowRightSvg from 'assets/icons/arrow-right.svg';
import timerSvg from 'assets/icons/timer.svg';
import shareSvg from 'assets/icons/share.svg';
import feedbackSvg from 'assets/icons/feedback-white.svg';
import { useMobile, useVideoHeight } from 'hooks';

import {
  AccessBackground,
  TitleContainer,
  TextContainer,
  FooterContainer,
  SubTitle,
  AccessIcon,
  AccessItemContainer,
  AccessItem,
  Image,
  AccessText,
  Button,
  BottomText,
} from './AccessCardMobile.style';
import accessItems from './AccessItemsMobile';

function AccessCardMobile() {
  const [redirect, setRedirect] = useState('');
  const [errorMessageDisplayed, setErrorMessageDisplayed] = useState(false);

  const videoHeight = useVideoHeight();
  const isMobile = useMobile();

  const requestAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          facingMode: 'user',
          ...(isMobile
            ? {
                width: 480,
                height: 600,
              }
            : {
                width: 1280,
                height: videoHeight,
              }),
        },
      });

      // @ts-ignore
      navigator.mediaStream = stream;

      setRedirect('/livedemo/mascara');
    } catch (err) {
      console.log(err);
      if (!errorMessageDisplayed) {
        notification.error({
          message: 'Sem acesso à câmera',
          description:
            'Verifique na barra do navegador se há uma solicitação ou um ícone de câmera',
        });
        setErrorMessageDisplayed(true);
      }
    }
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <AccessBackground>
      <TitleContainer>
        <Title style={{ fontSize: '2.5rem' }} bold color="white">
          Live Demo
        </Title>
        <SubTitle>
          <strong>Autorize</strong> o acesso à sua câmera
        </SubTitle>
      </TitleContainer>
      <TextContainer>
        <AccessItemContainer>
          {accessItems.map((item, i) => (
            <AccessItem>
              <Image src={[timerSvg, shareSvg, feedbackSvg][i]} />
              <AccessText>{item}</AccessText>
            </AccessItem>
          ))}
        </AccessItemContainer>
      </TextContainer>
      <FooterContainer>
        <Button onClick={requestAccess}>
          Iniciar
          <AccessIcon src={arrowRightSvg} />
        </Button>
        <BottomText>
          Todos os dados digitais registrados pelo Avantia Live Demo serão
          excluídos após um curto período de tempo. Conheça mais sobre a
          política de privacidade{' '}
          <a href="https://www.avantia.com.br/">clicando aqui</a>.
        </BottomText>
      </FooterContainer>
    </AccessBackground>
  );
}

export default AccessCardMobile;
