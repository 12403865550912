import styled from 'styled-components';

import { flexcc } from 'styles/variables';

export const BackgroundBase = styled.div`
  ${flexcc}
  width: 100%;
  height: calc(100vh - 80px);
  flex-flow: column;
  position: relative;
  background-color: #E8E8E8;

  @media only screen and (max-width: 700px) {
    min-height: calc(100vh - ${({ mobileHigher }: { mobileHigher: boolean }) => (mobileHigher ? '200px' : '160px')});
  }
`;
