import React from 'react';
import { Col, Row } from 'antd';

import { SecondaryBackground, PageCard } from 'components/atoms';
import { ChooseCard } from 'components/molecules';
import { useMobile } from 'hooks';

function Choose() {
  const isMobile = useMobile(600);

  return (
    <>
      <SecondaryBackground mobileHigher>
        {isMobile ? (
          <ChooseCard />
        ) : (
          <PageCard title="Seletor de Analíticos">
            <Row style={{ height: '100%' }} gutter={[10, 10]}>
              <Col span={24}>
                <ChooseCard />
              </Col>
            </Row>
          </PageCard>
        )}
      </SecondaryBackground>
    </>
  );
}

export default Choose;
