import React from 'react';

const getContainerWidth = (
  containerRef: React.RefObject<HTMLDivElement>,
  dimensions: number[],
  mobile?: Boolean,
) => {
  if (containerRef?.current?.parentElement) {
    const desiredHeight = 0.85;
    const mobileWidth = 0.9;

    const parent = containerRef.current.parentElement;
    const parentStyle = getComputedStyle(parent);
    const parentHeight = parseFloat(parentStyle.height) * desiredHeight;
    const parentWidth = (
      parseFloat(parentStyle.width)
      - (parseInt(parentStyle.paddingLeft, 10) * 2)
    ) * (mobile ? 1 : mobileWidth);

    const [videoHeight, videoWidth] = dimensions;

    const desiredWidth = (parentHeight * videoWidth) / videoHeight;

    if (desiredWidth > parentWidth) {
      const newHeight = (parentWidth * videoHeight) / videoWidth;
      return { height: newHeight, width: parentWidth };
    }

    return { height: `${desiredHeight * 100}%`, width: desiredWidth };
  }
  return { height: dimensions[0], width: dimensions[1] };
};

export default getContainerWidth;
